import React from 'react';
import { 
  Typography, 
  Box, 
  List, 
  ListItem, 
  ListItemText, 
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const TodaysFitnessPlan = ({ workout }) => {
  const [expanded, setExpanded] = React.useState(false); // panel0 to open first accordion

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const theme = useTheme();

  if (!workout) {
    return (
      <Box sx={{ mt: 1, mb: 1 }}>
        <Typography variant="h6" color="textSecondary">
          Its a rest day! well done!
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ mt: 1, mb: 1 }}>
      <Typography variant="h6" gutterBottom>
        {workout.workout_type} - {workout.duration_minutes} minutes
      </Typography>

      <Divider sx={{ mt: 1, mb: 1 }} />
      
      <Box>
          {workout.equipment_needed && workout.equipment_needed.length > 0 && (
            <Typography variant="subtitle2" color="textSecondary">
              <strong style={{ color: theme.palette.error.main }}>
                Equipment Needed: 
              </strong> {workout.equipment_needed.join(', ')}
            </Typography>
          )}
          {workout.injury_modifications && workout.injury_modifications.length > 0 && (
            <Typography variant="subtitle2" color="textSecondary">
              <strong style={{ color: theme.palette.error.main }}>
                Injury Modifications:
              </strong> {workout.injury_modifications.join(', ')}
            </Typography>
          )}
      </Box>

      {(workout.equipment_needed?.length > 0 || workout.injury_modifications?.length > 0) && (
        <Divider sx={{ mt: 1, mb: 1 }} />
      )}

      <Box mt={2}>
        {workout.exercises.map((exercise, index) => (
          <Accordion square key={index} sx={{ mb: 2 }} elevation={1} expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Box sx={{ width: '100%' }}>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: theme.palette.primary.main }}>
                  {exercise.exercise_name}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {exercise.muscle_group}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Sets: {exercise.sets} | Reps: {exercise.reps}
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <List dense>
                {exercise.description.map((step, idx) => (
                  <ListItem key={idx}>
                    <ListItemText primary={`${idx + 1}. ${step}`} />
                  </ListItem>
                ))}
              </List>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Box>
  );
};

export default TodaysFitnessPlan; 
