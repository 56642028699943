import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFlashMessage } from '../../context/FlashMessageContext';
import { createConsumer } from '@rails/actioncable';

const FitnessPlanChannel = ({ userId }) => {
  const { addFlashMessage } = useFlashMessage();
  const navigate = useNavigate();
  const consumerRef = useRef(null);
  const subscriptionRef = useRef(null);
  const reconnectTimeoutRef = useRef(null);
  const mountedRef = useRef(true);
  const MAX_RECONNECT_ATTEMPTS = 3;
  const reconnectAttemptsRef = useRef(0);

  useEffect(() => {
    mountedRef.current = true;
    reconnectAttemptsRef.current = 0;

    const setupWebSocket = () => {
      if (!userId || !mountedRef.current) return;

      // Clear any existing connections first
      if (subscriptionRef.current) {
        subscriptionRef.current.unsubscribe();
        subscriptionRef.current = null;
      }
      if (consumerRef.current) {
        consumerRef.current.disconnect();
        consumerRef.current = null;
      }

      try {
        const wsUrl = process.env.NODE_ENV === 'production'
          ? 'wss://pauls-fitness-app-29874b7e6de3.herokuapp.com/cable'
          : 'ws://localhost:3000/cable';
        
        // Create consumer with authentication
        consumerRef.current = createConsumer(`${wsUrl}?user_id=${userId}`);

        // Create subscription with error handling
        subscriptionRef.current = consumerRef.current.subscriptions.create(
          { 
            channel: 'FitnessPlanChannel',
            user_id: userId 
          },
          {
            connected: () => {
              reconnectAttemptsRef.current = 0;
            },
            disconnected: () => {
              console.log('[WebSocket] Disconnected');
              
              if (mountedRef.current && reconnectAttemptsRef.current < MAX_RECONNECT_ATTEMPTS) {
                reconnectAttemptsRef.current += 1;
                console.log(`[WebSocket] Attempt ${reconnectAttemptsRef.current} of ${MAX_RECONNECT_ATTEMPTS}`);
                
                reconnectTimeoutRef.current = setTimeout(() => {
                  if (mountedRef.current) {
                    setupWebSocket();
                  }
                }, 3000);
              } else if (reconnectAttemptsRef.current >= MAX_RECONNECT_ATTEMPTS) {
                addFlashMessage('Connection lost. Please refresh the page.', true);
              }
            },
            rejected: () => {
              console.log('[WebSocket] Subscription rejected');
              addFlashMessage('Connection rejected', true);
            },
            received: (data) => {
              if (!mountedRef.current) return;
              console.log('[WebSocket] Received:', data);
              
              if (data.type === 'status_update') {
                if (data.status === 'success') {
                  addFlashMessage('Your fitness plan is ready!', false);
                } else if (data.status === 'error') {
                  addFlashMessage(data.data.message || 'An error occurred', true);
                }
              }
            }
          }
        );
      } catch (error) {
        console.error('[WebSocket] Setup error:', error);
        if (mountedRef.current) {
          addFlashMessage('Failed to establish connection', true);
        }
      }
    };

    setupWebSocket();

    // Cleanup function
    return () => {
      mountedRef.current = false;
      
      if (reconnectTimeoutRef.current) {
        clearTimeout(reconnectTimeoutRef.current);
      }
      if (subscriptionRef.current) {
        subscriptionRef.current.unsubscribe();
      }
      if (consumerRef.current) {
        consumerRef.current.disconnect();
      }
      
      consumerRef.current = null;
      subscriptionRef.current = null;
      reconnectTimeoutRef.current = null;
    };
  }, [userId, addFlashMessage, navigate]);

  return null;
};

export default FitnessPlanChannel; 