import React, { useEffect, useState } from 'react';
import { fetchFitnessPlans, createFitnessPlan } from '../../api/fitnessPlan';
import WeekTimeline from './WeekTimeline';
import { 
  Table, 
  TableBody,
  TableRow,
  TableContainer, 
  TableHead, 
  Paper, 
  CircularProgress, 
  Typography, 
  Box 
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import FitnessDietaryPlanHeader from './FitnessDietaryPlanHeader';
import { useUserAccount } from '../../context/UserAccountContext';
import AcceptTermsBanner from '../Shared/AcceptTermsBanner';
import { useFlashMessage } from '../../context/FlashMessageContext';

// Styled TableCell
const StyledTableCell = styled('td')(({ theme }) => ({
  padding: theme.spacing(2),
  fontSize: '0.95rem',
  color: theme.palette.text.primary,
}));

// Styled TableRow
const StyledTableRow = styled('tr')(({ theme, index }) => ({
  backgroundColor: index % 2 === 0 
    ? theme.palette.mode === 'dark' 
      ? theme.palette.grey[800] 
      : theme.palette.grey[50] 
    : theme.palette.mode === 'dark' 
      ? theme.palette.grey[900] 
      : theme.palette.common.white,
  cursor: 'pointer',
  borderBottom: `1px solid ${theme.palette.divider}`,
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const FitnessDietaryPlans = () => {
  const { userAccount, userTermsAccepted, loading } = useUserAccount();
  const { addFlashMessage } = useFlashMessage();
  const navigate = useNavigate();
  const location = useLocation();

  const [isSending, setIsSending] = useState(false);
  const [fitnessPlans, setFitnessPlans] = useState([]);
  const [selectedPlanIndex, setSelectedPlanIndex] = useState(null);
  const [showTimeline, setShowTimeline] = useState(false);

  useEffect(() => {
    const getFitnessPlans = async () => {
      try {
        const data = await fetchFitnessPlans(userAccount.id);
        setFitnessPlans(data);
      } catch (error) {
        console.error('Error fetching fitness plans:', error);
        addFlashMessage('Error fetching fitness plans.', true);
      }
    };

    if (userAccount.id) {
      getFitnessPlans();
    }

    const intervalId = setInterval(() => {
      if (userAccount.id) {
        getFitnessPlans();
      }
    }, 5000); // Poll every 5 seconds

    return () => clearInterval(intervalId);
  }, [userAccount.id, addFlashMessage]);

  useEffect(() => {
    // Check if we have a plan ID in the URL
    const planId = new URLSearchParams(location.search).get('planId');
    if (planId) {
      const index = fitnessPlans.findIndex(plan => plan.id === parseInt(planId));
      if (index !== -1) {
        setSelectedPlanIndex(index);
        setShowTimeline(true);
      }
    } else {
      setSelectedPlanIndex(null);
      setShowTimeline(false);
    }
  }, [location.search, fitnessPlans]);

  const handleRowClick = (index) => {
    const planId = fitnessPlans[index].id;
    navigate(`/plans?planId=${planId}`);
  };

  const handleBackClick = () => {
    navigate('/plans');
  };

  const sendDataToAI = async () => {
    setIsSending(true);
    navigate('/building-plan');
    try {
      await createFitnessPlan();
      addFlashMessage('New Fitness Plan being created. We will let you know when it is ready.', false);
      navigate('/plans');
    } catch (error) {
      console.error('Error calling AI Assistant:', error);
      addFlashMessage('There was an unexpected error.', true);
      navigate('/plans');
    } finally {
      setIsSending(false);
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  const formattedDate = (date) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(date).toLocaleDateString('en-US', options);
  };

  return (
    <>
      {userTermsAccepted ? (
        <Box sx={{ p: 2 }}>
          <FitnessDietaryPlanHeader 
            onBackClick={handleBackClick} 
            onCreatePlanClick={sendDataToAI} 
            isSending={isSending} 
            showBackButton={showTimeline}
            userAccount={userAccount}
          />
          {fitnessPlans.length > 0 ? (
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
              <Box my={2}>
                <Typography>Select an existing plan or create a new one!</Typography>
              </Box>
              <Typography variant="h5" gutterBottom>Your Weekly Plans</Typography>
              {!showTimeline ? (
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                      <TableRow sx={{ backgroundColor: 'primary.main' }}>
                        <StyledTableCell>Goal</StyledTableCell>
                        <StyledTableCell align="right">Level</StyledTableCell>
                        <StyledTableCell align="right">Created</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {fitnessPlans.map((plan, index) => (
                        <StyledTableRow key={plan.id} index={index} onClick={() => handleRowClick(index)}>
                          <StyledTableCell component="th" scope="row">
                            {plan["fitness_plan"]?.["fitness_plan"]?.["fitness_goals"] || 'N/A'}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {plan["fitness_plan"]?.["fitness_plan"]?.["fitness_level"] || 'N/A'}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {formattedDate(plan.created_at)}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <WeekTimeline
                  fitnessPlan={fitnessPlans[selectedPlanIndex]?.fitness_plan}
                  dietaryPlan={fitnessPlans[selectedPlanIndex]?.dietary_plan}
                  planId={fitnessPlans[selectedPlanIndex]?.id}
                />
              )}
            </Box>
          ) : (
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" my={4}>
              <Typography variant="h5">Create a plan to view it here!</Typography>
            </Box>
          )}
        </Box>
      ) : (
        <AcceptTermsBanner />
      )}
    </>
  );
};

export default FitnessDietaryPlans;
