import * as React from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Paper from '@mui/material/Paper';

// Icons
import GitHubIcon from '@mui/icons-material/GitHub';
import TwitterIcon from '@mui/icons-material/Twitter';

const LandingFooter = () => {
  return (
    <>
      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
        <BottomNavigation showLabels>
          <BottomNavigationAction
            value="x"
            href="https://x.com/PStorberg"
            icon={<TwitterIcon />}
          />
          <BottomNavigationAction 
            label="Terms of Service" 
            value="terms"
            href="/terms"
          />
          <BottomNavigationAction
            value="github"
            href="https://github.com/PaulStorberg"
            icon={<GitHubIcon />}
          />
        </BottomNavigation>
      </Paper>
    </>
  );
};

export default LandingFooter;