import React, { useEffect, useState } from "react";
import { useFlashMessage } from '../../context/FlashMessageContext';
import { useNavigate } from 'react-router-dom';
import { updateUserAccount, fetchUserAccount } from '../../api/userAccount';

// MUI Components
import {
  Box,
  Button,
  Typography,
} from '@mui/material';

const appName = "Thryv";

const AppName = () => {
  return (
    <Typography component="span" variant="inherit" sx={{ fontWeight: 'bold' }}>
      {appName}
    </Typography>
  );
};

const LastUpdated = () => {
  return (
    <Typography variant="body2" color="textSecondary">
      Last Updated: Oct 22nd 2024
    </Typography>
  );
};

export default function TermsOfService({ userAccountId }) {
    const navigate = useNavigate();
    const [userPresent, setUserPresent] = useState(false);
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [termsAcceptedAt, setTermsAcceptedAt] = useState(null);
    const { addFlashMessage } = useFlashMessage();

    const formatDate = (dateString) => {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(dateString).toLocaleDateString(undefined, options);
    };

    useEffect(() => {
        const getUserAccount = async () => {
          try {
            const data = await fetchUserAccount(userAccountId);

            setUserPresent(data);
            setTermsAccepted(data.terms_accepted);
            setTermsAcceptedAt(data.terms_accepted_at);

          } catch (error) {
            console.error('Couldn\'t fetch user account:', error);
          }
        };
      
        getUserAccount();
      }, [addFlashMessage, userAccountId]);  

    const handleTermsSubmit = async () => {
        try {
          console.log('Submitting terms acceptance...');
          await updateUserAccount({ terms_accepted: true, terms_accepted_at: new Date() });
          console.log('Terms accepted successfully');
          setTermsAccepted(true);
          navigate('/fitness-preferences');
          window.location.reload();
        } catch (error) {
          console.error('Failed to accept terms:', error);
        }
      };

    return (
      <Box
        sx={{
          maxWidth: '800px',
          margin: 'auto',
          padding: 4,
          mt: 5,
          mb: 5,
        }}
      >
        <Box sx={{ textAlign: 'center', mb: 4 }}>
          <Typography variant="h4" component="h1" gutterBottom>
            Terms of Service
          </Typography>
          <Typography variant="h6" component="h3">
            By <AppName />
          </Typography>
          <LastUpdated />
        </Box>
        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" gutterBottom>
            1. Acceptance of Terms
          </Typography>
          <Typography paragraph>
            By accessing or using <AppName /> ("the App"), you agree to be bound by these Terms of Service ("Terms"). If you do not agree to these Terms, you may not use the App.
          </Typography>

          <Typography variant="h6" gutterBottom>
            2. Use of the App
          </Typography>
          <Typography paragraph>
            The App is intended for informational and educational purposes only. It does not provide medical, fitness, or dietary advice. You should always consult with a healthcare professional before starting any fitness or dietary program. The use of the App does not replace professional medical advice, diagnosis, or treatment.
          </Typography>

          <Typography variant="h6" gutterBottom>
            3. Assumption of Risk
          </Typography>
          <Typography paragraph>
            By using the App, you acknowledge that engaging in physical activities and changes in diet involve inherent risks, including the risk of physical injury or illness. You agree to assume full responsibility for any risks, injuries, or damages that may occur in connection with your use of the App.
          </Typography>

          <Typography variant="h6" gutterBottom>
            4. No Liability for Injuries
          </Typography>
          <Typography paragraph>
            <AppName /> and its creators, developers, and affiliates are not liable for any injuries or damages that result from the use of the App, including injuries or health issues that arise from performing workouts or following dietary suggestions provided by the App.
          </Typography>

          <Typography variant="h6" gutterBottom>
            5. Dietary and Allergy Disclaimer
          </Typography>
          <Typography paragraph>
            The dietary suggestions provided by the App are generated based on user input. It is your responsibility to verify the ingredients and nutritional content to avoid any potential allergies or other adverse reactions. <AppName /> is not liable for any allergic reactions or health issues that may arise from following dietary suggestions provided by the App.
          </Typography>

          <Typography variant="h6" gutterBottom>
            6. No Warranty
          </Typography>
          <Typography paragraph>
            The App is provided "as is" without warranties of any kind, either express or implied. <AppName /> disclaims all warranties, including but not limited to implied warranties of merchantability, fitness for a particular purpose, and non-infringement.
          </Typography>

          <Typography variant="h6" gutterBottom>
            7. Limitation of Liability
          </Typography>
          <Typography paragraph>
            To the maximum extent permitted by law, in no event shall <AppName /> or its affiliates be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, arising out of or in connection with your use of the App.
          </Typography>

          <Typography variant="h6" gutterBottom>
            8. Indemnification
          </Typography>
          <Typography paragraph>
            You agree to indemnify and hold harmless <AppName />, its affiliates, and its respective officers, directors, employees, and agents from and against any claims, liabilities, damages, losses, and expenses, including reasonable attorney's fees, arising out of or in any way connected with your access to or use of the App.
          </Typography>

          <Typography variant="h6" gutterBottom>
            9. Governing Law
          </Typography>
          <Typography paragraph>
            <AppName /> reserves the right to change these Terms at any time. Your continued use of the App after any such changes constitutes your acceptance of the revised Terms.
          </Typography>

          <Typography variant="h6" gutterBottom>
            10. Minimum Age Requirements
          </Typography>
          <Typography paragraph>
            <AppName /> is not intended for individuals under the age of 18. By using the App, you represent and warrant that you are at least 18 years of age.
          </Typography>
          
          <Typography variant="h6" gutterBottom>
            11. Use of User Metrics
          </Typography>
          <Typography paragraph>
            <AppName /> uses user metrics to improve its products and services. By using the App, you agree to this use.
          </Typography>

          <Typography variant="h6" gutterBottom>
            12. Termination
          </Typography>
          <Typography paragraph>
            These Terms may be terminated by <AppName /> or its affiliates without notice.
          </Typography>
        </Box>
        <Box sx={{ textAlign: 'center' }}>
          {userPresent ? (
            !termsAccepted ? (
              <Button
                variant="contained"
                color="primary"
                onClick={handleTermsSubmit}
                sx={{
                  mb: 2,
                  bgcolor: '#d32f2f',
                  '&:hover': {
                    bgcolor: '#b71c1c'
                  },
                  borderRadius: '25px',
                  padding: '10px 20px',
                  mt: 2,
                }}
              >
                Accept Terms
              </Button>
            ) : (
              <Box sx={{ mt: 2 }}>
                <Typography variant="body1" color="success.main">
                  You have accepted the Terms on {formatDate(termsAcceptedAt)}.
                </Typography>
              </Box>
            )
          ) : (
            <Button
              variant="outlined"
              color="primary"
              onClick={() => navigate('/')}
              sx={{
                mb: 2,
                borderColor: '#d32f2f',
                color: '#d32f2f',
                '&:hover': {
                  borderColor: '#b71c1c',
                  color: '#b71c1c',
                },
                borderRadius: '25px',
                padding: '10px 20px',
                mt: 2,
              }}
            >
              Signup
            </Button>
          )}
          <LastUpdated />
        </Box>
      </Box>
    )
}
