import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const disabilityOptions = [
  { value: 'Visual Impairment', label: 'Visual Impairment' },
  { value: 'Hearing Impairment', label: 'Hearing Impairment' },
  { value: 'Mobility Impairment', label: 'Mobility Impairment' },
  { value: 'Cognitive Impairment', label: 'Cognitive Impairment' },
  { value: 'Speech Impairment', label: 'Speech Impairment' },
  { value: 'Other', label: 'Other' }
];

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const Disabilities = ({ userPreferences, handleChange }) => {
  const handleSelectChange = (event, value) => {
    handleChange({
      target: {
        name: 'personal_info.disabilities',
        value: value.map(disability => disability.value)
      }
    });
  };

  return (
    <Autocomplete
      multiple
      id="checkboxes-tags-demo"
      options={disabilityOptions}
      disableCloseOnSelect
      getOptionLabel={(option) => option.label}
      renderOption={(props, option, { selected }) => {
        const { key, ...optionProps } = props;
        return (
          <li key={key} {...optionProps}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.label}
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField 
          {...params} 
          label="Disabilities" 
          placeholder="Select Disabilities" 
          onFocus={() => window.scrollTo(0, 0)}
        />
      )}
      onChange={handleSelectChange}
      value={disabilityOptions.filter(disability => userPreferences.disabilities.includes(disability.value))}
    />
  );
};

export default Disabilities;
