import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { TextField, Button, Typography, Box } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useTheme } from '@mui/material/styles';
import { resetPassword } from '../../api/auth';

export default function ResetPassword() {
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [message, setMessage] = useState({ type: '', content: '' });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();

  const searchParams = new URLSearchParams(location.search);
  const resetToken = searchParams.get('reset_password_token');

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitting) return; // Prevent double submission
    
    try {
      setIsSubmitting(true);
      const data = await resetPassword(resetToken, password, passwordConfirmation);
      
      setMessage({
        type: 'success',
        content: 'Password has been reset successfully. Redirecting to login...'
      });
      
      // Clear form
      setPassword('');
      setPasswordConfirmation('');
      
      // Redirect after a delay
      setTimeout(() => {
        navigate('/signin');
      }, 2000);
    } catch (error) {
      setMessage({
        type: 'error',
        content: error.response?.data?.message || 'An error occurred. Please try again.'
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Box p={4} maxWidth="400px" mx="auto" mt={10}>
      <Typography sx={{ fontWeight: 'bold', color: theme.palette.primary.main }} variant="h5" align="center" gutterBottom>
        Reset Password
      </Typography>

      {message.content && (
        <Box color={message.type === 'error' ? 'error.main' : 'success.main'} sx={{ textAlign: 'center', mb: 2 }}>
          <Typography variant="body2">{message.content}</Typography>
        </Box>
      )}

      <form onSubmit={handleSubmit}>
        <Grid container direction="column" spacing={3}>
          <Grid item="true">
            <TextField
              fullWidth
              type="password"
              name="password"
              label="New Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              disabled={isSubmitting}
            />
          </Grid>

          <Grid item="true">
            <TextField
              fullWidth
              type="password"
              name="password_confirmation"
              label="Confirm New Password"
              value={passwordConfirmation}
              onChange={(e) => setPasswordConfirmation(e.target.value)}
              required
              disabled={isSubmitting}
            />
          </Grid>

          <Grid item="true">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              sx={{
                bgcolor: '#d32f2f',
                '&:hover': {
                  bgcolor: '#b71c1c'
                },
                borderRadius: '25px',
                padding: '10px 0'
              }}
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Resetting Password...' : 'Reset Password'}
            </Button>
          </Grid>

          <Grid item="true">
            <Typography variant="body2" align="center">
              <Button
                component="a"
                href="/signin"
                color="primary"
                sx={{ textTransform: 'none' }}
              >
                Sign in to your account here
              </Button>
            </Typography>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
} 