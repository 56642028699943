import React from 'react';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';

const ageOptions = [
  { value: '18-24', label: '18-24' },
  { value: '25-34', label: '25-34' },
  { value: '35-44', label: '35-44' },
  { value: '45-54', label: '45-54' },
  { value: '55-64', label: '55-64' },
  { value: '65+', label: '65+' }
];

const UserAge = ({ userPreferences, handleChange }) => {
  const handleSelectChange = (event) => {
    handleChange({
      target: {
        name: 'personal_info.user_age',
        value: event.target.value
      }
    });
  };

  return (
    <div className="max-w-md">
      <FormControl fullWidth>
        <InputLabel id="user_age_label">Age Range</InputLabel>
        <Select
          id="user_age"
          name="personal_info.user_age"
          label="Age Range"
          options={ageOptions}
          value={userPreferences.user_age || ''}
          onChange={handleSelectChange}
        >
          {ageOptions.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default UserAge;
