import React from 'react';

// MUI Imports
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

// Field Imports
import FitnessGoals from './formFields/PersonalInfo/FitnessGoals';
import ActivityLevel from './formFields/PersonalInfo/ActivityLevel';
import AvailableEquipment from './formFields/FitnessPlan/AvailableEquipment';
import PreferredWorkoutEquipment from './formFields/FitnessPlan/PreferredWorkoutEquipment';
import BlacklistedWorkoutEquipment from './formFields/FitnessPlan/BlacklistedWorkoutEquipment';
import WorkoutPreferences from './formFields/FitnessPlan/WorkoutPreferences';
import AccessToGym from './formFields/FitnessPlan/AccessToGym';
import AmountOfTime from './formFields/FitnessPlan/AmountOfTime';
import DaysOfWeek from './formFields/FitnessPlan/DaysOfWeek';

const Item = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
}));

const FitnessPlanFormHeader = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.primary.main,
  textAlign: 'center',
}));

const FitnessPlanForm = ({ userPreferences, handleChange }) => (
  <Box sx={{ flexGrow: 1 }}>
    <Grid container spacing={1} justifyContent="center" columnSpacing={{ xs: 1, sm: 2 }}>
      <Grid item="true" size={{ xs: 12, md: 6 }}>
        <FitnessPlanFormHeader>
          Date/Time Preferences
        </FitnessPlanFormHeader>
        <Item>
          <DaysOfWeek userPreferences={userPreferences.fitness_plan} handleChange={handleChange} />
        </Item>
        <Item>
          <AmountOfTime userPreferences={userPreferences.fitness_plan} handleChange={handleChange} />
        </Item>
      </Grid>

      <Grid item="true" size={{ xs: 12, md: 6 }}>
        <FitnessPlanFormHeader>
          Access Preferences
        </FitnessPlanFormHeader>
        <Item>
          <AccessToGym userPreferences={userPreferences.fitness_plan} handleChange={handleChange} />
        </Item>
      </Grid>
    </Grid>

    <Grid container spacing={1} justifyContent="center" columnSpacing={{ xs: 1, sm: 2 }}>
      <Grid item="true" size={{ xs: 12, md: 6 }}>
        <FitnessPlanFormHeader>
          Fitness Preferences
        </FitnessPlanFormHeader>
        <Item>
          <WorkoutPreferences userPreferences={userPreferences.fitness_plan} handleChange={handleChange} />
        </Item>
        <Item>
          <FitnessGoals userPreferences={userPreferences.fitness_plan} handleChange={handleChange} />
        </Item>
        <Item>
          <ActivityLevel userPreferences={userPreferences.fitness_plan} handleChange={handleChange} />
        </Item>
      </Grid>

      <Grid item="true" size={{ xs: 12, md: 6 }}>
        <FitnessPlanFormHeader>
          Equipment Preferences
        </FitnessPlanFormHeader>
        {!userPreferences.fitness_plan.access_to_gym && (
          <Item>
            <AvailableEquipment userPreferences={userPreferences.fitness_plan} handleChange={handleChange} />
          </Item>
        )}
        {userPreferences.fitness_plan.access_to_gym && (
          <>
            <Item>
              <PreferredWorkoutEquipment userPreferences={userPreferences.fitness_plan} handleChange={handleChange} />
            </Item>
            <Item>
              <BlacklistedWorkoutEquipment userPreferences={userPreferences.fitness_plan} handleChange={handleChange} />
            </Item>
          </>
        )}
      </Grid>
    </Grid>
  </Box>
);

export default FitnessPlanForm;
