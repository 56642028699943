import React from 'react';
import { Alert, Snackbar, Link } from '@mui/material';
import { useFlashMessage } from '../../context/FlashMessageContext';
import { Box } from '@mui/material';

function FlashMessage() {
  const { flashMessages, removeFlashMessage, handleLinkClick } = useFlashMessage();
  
  const renderMessageContent = (msg) => {
    // Ensure msg is properly structured
    if (typeof msg !== 'object' || !msg.text) {
      return String(msg); // Convert to string if it's not an object with text
    }

    // If there's no link data, just return the text
    if (!msg.linkText || !msg.linkPath || !msg.text.includes(msg.linkText)) {
      return msg.text;
    }

    // Handle message with link
    const parts = msg.text.split(msg.linkText);
    return (
      <>
        {parts[0]}
        <Link
          component="button"
          onClick={() => handleLinkClick(msg.id, msg.linkPath)}
          sx={{
            color: 'inherit',
            textDecoration: 'underline',
            cursor: 'pointer'
          }}
        >
          {msg.linkText}
        </Link>
        {parts[1]}
      </>
    );
  };
  
  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        right: 12,
        zIndex: 9999,
        pointerEvents: 'auto'
      }}
    >
      {flashMessages.map((msg) => (
        <Snackbar
          key={msg.id}
          open={true}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          autoHideDuration={9000}
          onClose={() => removeFlashMessage(msg.id)}
          sx={{ 
            position: 'relative',
            pointerEvents: 'auto'
          }}
        >
          <Alert
            onClose={() => removeFlashMessage(msg.id)}
            severity={msg.error ? 'error' : 'success'}
            variant="filled"
            sx={{ width: '100%' }}
          >
            {renderMessageContent(msg)}
          </Alert>
        </Snackbar>
      ))}
    </Box>
  );
}

export default FlashMessage;
