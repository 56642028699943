import React, { useState } from "react";
import { 
  Button, 
  Dialog, 
  DialogContent, 
  DialogActions, 
  Box,
  DialogTitle,
  IconButton
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

export default function EventModal({ children, planType, workoutType }) {
  const [openModal, setOpenModal] = useState(false);

  const buttonText = planType === "fitness" ? workoutType : "Dietary Plan";

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleOpen = () => {
    setOpenModal(true);
  };

  return (
    <>
      <Button 
        variant="contained" 
        onClick={handleOpen}
        size="small"
        fullWidth
      >
        {buttonText}
      </Button>
      <Dialog 
        open={openModal} 
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        aria-labelledby="modal-title"
      >
        <DialogTitle id="modal-title">
          {buttonText}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ p: 1 }}>
            {children}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined">
            Dismiss
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}