import { Button, Tooltip, Box, CircularProgress } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export default function FitnessDietaryPlanHeader({ onBackClick, onCreatePlanClick, isSending, showBackButton, userAccount }) {
  const CreatePlanButton = ({ onClick, isLoading, children }) => {
    const canCreateObject = userAccount?.admin || userAccount?.objects_created_this_month < 3;
    const button = (
      <span>
        <Button 
          variant="contained"
          onClick={onClick} 
          disabled={isLoading || !canCreateObject}
          sx={{ ml: showBackButton ? 2 : 0 }}
        >
          {isLoading ? <CircularProgress size={24} color="inherit" /> : children}
        </Button>
      </span>
    );

    return !canCreateObject ? (
      <Tooltip title="You have reached your maximum free plans for this month" arrow>
        {button}
      </Tooltip>
    ) : button;
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', m: 1, gap: 1, justifyContent: 'flex-end' }}>
      {showBackButton && (
        <Button
          variant="outlined"
          startIcon={<ArrowBackIcon />}
          onClick={onBackClick}
        >
          Back to Plans
        </Button>
      )}
      <CreatePlanButton 
        onClick={onCreatePlanClick} 
        isLoading={isSending}
      >
        Create New Plan
      </CreatePlanButton>
    </Box>
  );
}
