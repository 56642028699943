// React
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';

// Contexts
import { AuthProvider, useAuth } from './context/AuthContext';
import { UserAccountProvider, useUserAccount } from './context/UserAccountContext';
import { ModalProvider } from './context/ModalContext';
import { FlashMessageProvider } from './context/FlashMessageContext';

// Auth Components
import Signin from './components/Auth/Signin';
import Signup from './components/Auth/Signup';
import ForgotPassword from './components/Auth/ForgotPassword';
import ResetPassword from './components/Auth/ResetPassword';
import LandingHeader from './components/Landing/LandingHeader';
import LandingFooter from './components/Landing/LandingFooter';

// MUI Components
import '@fontsource/roboto';

// Toolpad Components
import { AppProvider } from '@toolpad/core/AppProvider';
import { DashboardLayout } from '@toolpad/core/DashboardLayout';

// Shared Components
import FlashMessage from './components/Shared/FlashMessage';
import TermsOfService from './components/Shared/TermsOfService';
import FitnessPlanChannel from './components/Shared/FitnessPlanChannel';

// Layout Components
import { toolpadTheme } from './components/Layout/Theme';
import { NAVIGATION } from './components/Layout/Navigation';
import { SidebarFooterAccount } from './components/Layout/AccountSidebar';

// User Pages
import HomePage from './components/User/HomePage';
import UserAccount from './components/UserAccount/UserAccount';
import FitnessDietaryPlans from './components/FitnessDietaryPlan/FitnessDietaryPlans';
import UserProfile from './components/User/UserProfile';
import AdminDashboard from './components/Admin/AdminDashboard';
import AdminUserView from './components/Admin/AdminUserView';

// Add this styled component near the top of the file
const ResponsiveLogo = styled('img')(({ theme }) => ({
  height: '40px',
  [theme.breakpoints.down('sm')]: {
    marginTop: '8px',
    height: '30px',
  },
}));

// Add this new component
const ThemedLogo = styled(ResponsiveLogo)(({ theme }) => ({
  content: `url(${theme.palette.mode === 'light' ? '/full_logo_light.png' : '/full_logo_dark.png'})`,
}));

// Custom Router Hook
function useCustomRouter() {
  const navigate = useNavigate();
  const location = useLocation();

  return React.useMemo(
    () => ({
      pathname: location.pathname,
      navigate: (path) => navigate(path),
      push: (path) => navigate(path),
      replace: (path) => navigate(path, { replace: true }),
      back: () => navigate(-1),
      forward: () => navigate(1),
      go: (delta) => navigate(delta),
      createHref: (to) => to,
      listen: () => () => {}, // Noop function for the listener
      searchParams: new URLSearchParams(location.search),
      search: location.search,
      query: Object.fromEntries(new URLSearchParams(location.search)),
    }),
    [navigate, location]
  );
}

function AppWithInterceptors() {
  const { isAuthenticated, loading, user, handleSignout, fitnessPlans } = useAuth();
  const router = useCustomRouter();
  const { userAccount } = useUserAccount();

  const authentication = React.useMemo(() => ({
    signIn: () => router.navigate('/signin'),
    signOut: () => {
      handleSignout();
      router.navigate('/');
    },
    isAuthenticated,
  }), [router, handleSignout, isAuthenticated]);

  if (loading) {
    return <div>Loading...</div>;
  }

  // Unauthenticated routes wrapped in AppProvider with light theme
  if (!isAuthenticated) {
    return (
      <AppProvider
        navigation={[]}
        router={router}
        theme={toolpadTheme}
        authentication={authentication}
        session={null}
        defaultColorScheme="light"
      >
        <FlashMessage />
        <LandingHeader />
        <Routes>
          <Route path="/" element={<Navigate to="/signup" replace />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/signin" element={<Signin />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/terms" element={<TermsOfService />} />
          <Route path="*" element={<Navigate to="/signup" />} />
        </Routes>
        <LandingFooter />
      </AppProvider>
    );
  }

  const isAdmin = user?.user_account?.admin || false;

  // Authenticated routes
  return (
    <AppProvider
      navigation={NAVIGATION(isAdmin)}
      router={router}
      theme={toolpadTheme}
      authentication={authentication}
      session={{
        user: {
          email: user?.email,
        }
      }}
      defaultColorScheme="light"
      branding={{
        logo: <ThemedLogo alt="Thryv Fitness" />,
        title: '',
      }}
    >
      {userAccount?.id && (
        <FitnessPlanChannel 
          key={`fitness-channel-${userAccount.id}`} 
          userId={userAccount.id} 
        />
      )}
      <DashboardLayout
        defaultSidebarCollapsed
        slots={{ 
          sidebarFooter: SidebarFooterAccount,
          toolbarAccount: () => null
        }}
        sidebarExpandedWidth={235}
      >
        <FlashMessage />
        <Routes>
          <Route path="/" element={<HomePage user={user} fitnessPlan={fitnessPlans?.[0]?.fitness_plan} />} />
          <Route path="/terms" element={<TermsOfService />} />
          <Route path="/fitness-preferences" element={<UserAccount />} />
          <Route path="/plans" element={<FitnessDietaryPlans />} />
          <Route path="/profile" element={<UserProfile />} />
          {isAdmin && (
            <>
              <Route path="/admin" element={<AdminDashboard />} />
              <Route path="/admin/users/:userId" element={<AdminUserView />} />
            </>
          )}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </DashboardLayout>
    </AppProvider>
  );
}

function App() {
  return (
    <Router>
      <AuthProvider>
        <ModalProvider>
          <UserAccountProvider>
            <FlashMessageProvider>
              <AppWithInterceptors />
            </FlashMessageProvider>
          </UserAccountProvider>
        </ModalProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;
