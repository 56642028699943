import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const dayOptions = [
  { value: 'Sunday', label: 'Sunday' },
  { value: 'Monday', label: 'Monday' },
  { value: 'Tuesday', label: 'Tuesday' },
  { value: 'Wednesday', label: 'Wednesday' },
  { value: 'Thursday', label: 'Thursday' },
  { value: 'Friday', label: 'Friday' },
  { value: 'Saturday', label: 'Saturday' }
];

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const DaysOfWeek = ({ userPreferences, handleChange }) => {
  const handleSelectChange = (event, value) => {
    handleChange({
      target: {
        name: 'fitness_plan.workout_days',
        value: value.map(day => day.value)
      }
    });
  };

  return (
    <Autocomplete
      multiple
      id="checkboxes-tags-demo"
      options={dayOptions}
      disableCloseOnSelect
      getOptionLabel={(option) => option.label}
      renderOption={(props, option, { selected }) => {
        const { key, ...optionProps } = props;
        return (
          <li key={key} {...optionProps}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.label}
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField 
          {...params} 
          label="Days of the Week" 
          placeholder="Select Days" 
          onFocus={() => window.scrollTo(0, 0)}
        />
      )}
      onChange={handleSelectChange}
      value={dayOptions.filter(day => userPreferences.workout_days.includes(day.value))}
    />
  );
};

export default DaysOfWeek;
