import React from 'react';

// MUI
import { 
  List, 
  ListItem, 
  ListItemText, 
  Paper, 
  Chip,
  Stack,
  Typography 
} from '@mui/material';

const PersonalPreferencesDisplay = ({ preferences }) => {
  if (!preferences) return null;

  return (
    <Paper elevation={0} sx={{ p: 1, maxWidth: 300 }}>
      <List dense disablePadding>
        <ListItem>
          <ListItemText 
            primary="Age"
            secondary={preferences.user_age || 'Not specified'}
          />
        </ListItem>
        <ListItem>
          <ListItemText 
            primary="BMI"
            secondary={preferences.user_bmi || 'Not specified'}
          />
        </ListItem>
        <ListItem>
          <ListItemText 
            primary="Height"
            secondary={preferences.user_height || 'Not specified'}
          />
        </ListItem>
        <ListItem>
          <ListItemText 
            primary="Weight"
            secondary={preferences.user_weight ? `${preferences.user_weight} lbs` : 'Not specified'}
          />
        </ListItem>
        <ListItem>
          <ListItemText 
            primary="Disabilities"
            secondary={
              preferences.disabilities?.length > 0 ? (
                <Stack direction="row" spacing={1} sx={{ mt: 0.5 }}>
                  {preferences.disabilities.map((disability, index) => (
                    <Chip 
                      key={index}
                      label={disability}
                      size="small"
                      variant="outlined"
                    />
                  ))}
                </Stack>
              ) : (
                'None'
              )
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText 
            primary="Fitness Goals"
            secondary={preferences.fitness_goals || 'Not specified'}
          />
        </ListItem>
        <ListItem>
          <ListItemText 
            primary="Activity Level"
            secondary={preferences.activity_level || 'Not specified'}
          />
        </ListItem>
        <ListItem>
          <ListItemText 
            primary="Pregnancy Status"
            secondary={
              <Chip 
                label={preferences.pregnancy_status ? 'Yes' : 'No'}
                color={preferences.pregnancy_status ? 'primary' : 'default'}
                size="small"
                sx={{ mt: 0.5 }}
              />
            }
          />
        </ListItem>
      </List>
    </Paper>
  );
};

export default PersonalPreferencesDisplay; 