import React from 'react';

// Shared imports
import EventModal from './EventModal';

// MUI imports
import { 
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Table,
  TableBody, 
  TableCell, 
  TableHead, 
  TableRow, 
  Typography, 
  Box, 
  TableContainer, 
  Paper,
  Stack
} from '@mui/material';
import { styled } from '@mui/material/styles';

// Icons
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Styled TableCell
const StyledTableCell = styled('td')(({ theme }) => ({
  padding: theme.spacing(2),
  fontSize: '0.95rem',
  color: theme.palette.text.primary,
}));

// Styled TableRow
const StyledTableRow = styled('tr')(({ theme, index }) => ({
  backgroundColor: index % 2 === 0 
    ? theme.palette.mode === 'dark' 
      ? theme.palette.grey[800] 
      : theme.palette.grey[50] 
    : theme.palette.mode === 'dark' 
      ? theme.palette.grey[900] 
      : theme.palette.common.white,
  cursor: 'pointer',
  borderBottom: `1px solid ${theme.palette.divider}`,
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const FitnessEvent = ({ workout }) => {
  const EquipmentNeeded = ({ equipment }) => {
    if (!equipment || equipment.length === 0 || equipment.includes("None") || equipment.includes("none")) {
      return null;
    }
  
    return (
      <Box sx={{ mb: 2 }}>
        <Typography component="span" fontWeight="bold">Equipment Needed:</Typography>
        <Typography component="span"> {equipment.join(', ')}</Typography>
      </Box>
    );
  };

  return (
    <Box>
      <EventModal planType={`fitness`} workoutType={workout.workout_type}>
        <>
          <Typography variant="h5" sx={{ mb: 2 }}>
            {workout.workout_type} for {workout.duration_minutes} minutes
          </Typography>
          <EquipmentNeeded equipment={workout.equipment_needed} />
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow sx={{ backgroundColor: 'primary.main'}}>
                  <StyledTableCell>Exercises</StyledTableCell>
                  <StyledTableCell>Sets x Reps</StyledTableCell>
                  <StyledTableCell>Muscle Group</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {workout.exercises.map((exercise, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell colSpan={3}>
                      <Accordion sx={{ backgroundColor: 'transparent', border: 'none', boxShadow: 'none', backgroundImage: 'none' }}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls={`panel${index}-content`}
                          id={`panel${index}-header`}
                          sx={{ 
                            backgroundColor: 'transparent',
                            minHeight: 'auto',
                            px: 2,
                            '& .MuiAccordionSummary-content': {
                              m: 1
                            }
                          }}
                        >
                          <Box sx={{ display: 'flex', width: '100%' }}>
                            <Box sx={{ width: '33%', fontWeight: 'medium' }}>{exercise.exercise_name}</Box>
                            <Box sx={{ width: '33%' }}>{exercise.sets} sets x {exercise.reps}</Box>
                            <Box sx={{ width: '33%' }}>{exercise.muscle_group}</Box>
                          </Box>
                        </AccordionSummary>
                        <AccordionDetails sx={{ 
                          px: 2, 
                          py: 1,
                        }}>
                          <Stack spacing={2}>
                            {exercise.description.map((description, descIndex) => (
                              <Typography key={descIndex}>
                                {descIndex + 1}. {description}
                              </Typography>
                            ))}
                          </Stack>
                        </AccordionDetails>
                      </Accordion>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      </EventModal>
    </Box>
  );
};

export default FitnessEvent;
