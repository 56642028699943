import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const blacklistedEquipmentOptions = [
  { value: 'Dumbbells', label: 'Dumbbells' },
  { value: 'Resistance Bands', label: 'Resistance Bands' },
  { value: 'Kettlebells', label: 'Kettlebells' },
  { value: 'Yoga Mat', label: 'Yoga Mat' },
  { value: 'Pull-up Bar', label: 'Pull-up Bar' },
  { value: 'Jump Rope', label: 'Jump Rope' },
  { value: 'Stability Ball', label: 'Stability Ball' },
  { value: 'Adjustable Bench', label: 'Adjustable Bench' },
  { value: 'Flat Bench', label: 'Flat Bench' },
  { value: 'Treadmill', label: 'Treadmill' },
  { value: 'Medicine Ball', label: 'Medicine Ball' },
  { value: 'Exercise Bike', label: 'Exercise Bike' },
  { value: 'Ankle Weights', label: 'Ankle Weights' },
  { value: 'Ab Wheel', label: 'Ab Wheel' },
  { value: 'Barbell', label: 'Barbell' },
  { value: 'Exercise Stepper', label: 'Exercise Stepper' },
  { value: 'Weighted Vest', label: 'Weighted Vest' },
  { value: 'Battle Ropes', label: 'Battle Ropes' },
  { value: 'Bodyweight', label: 'Bodyweight' },
  { value: 'Dip Station', label: 'Dip Station' },
  { value: 'Punching Bag', label: 'Punching Bag' }
];

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const BlacklistedWorkoutEquipment = ({ userPreferences, handleChange }) => {
  const handleSelectChange = (event, value) => {
    handleChange({
      target: {
        name: 'fitness_plan.blacklisted_workout_equipment',
        value: value.map(equipment => equipment.value)
      }
    });
  };

  return (
    <div className="max-w-md">
      <Autocomplete
        multiple
        id="blacklisted_workout_equipment"
        options={blacklistedEquipmentOptions}
        disableCloseOnSelect
        getOptionLabel={(option) => option.label}
        renderOption={(props, option, { selected }) => {
          const { key, ...optionProps } = props;
          return (
            <li key={key} {...optionProps}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.label}
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField 
            {...params} 
            label="Blacklisted Equipment" 
            placeholder="Select Equipment" 
            onFocus={() => window.scrollTo(0, 0)}
          />
        )}
        onChange={handleSelectChange}
        value={blacklistedEquipmentOptions.filter(option => userPreferences.blacklisted_workout_equipment.includes(option.value))}
      />
    </div>
  );
};

export default BlacklistedWorkoutEquipment;
