import React from 'react';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';

const sexOptions = [
  { value: 'prefer_not_to_say', label: 'Prefer not to say' },
  { value: 'male', label: 'Male' },
  { value: 'female', label: 'Female' }
];

const UserSex = ({ userPreferences, handleChange }) => {
  const handleSelectChange = (event) => {
    handleChange({
      target: {
        name: 'personal_info.user_sex',
        value: event.target.value
      }
    });
  };

  return (
    <div className="max-w-md">
      <FormControl fullWidth>
        <InputLabel id="user_sex_label">Sex</InputLabel>
        <Select
          id="user_sex"
          name="personal_info.user_sex"
          label="Sex"
          options={sexOptions}
          value={userPreferences.user_sex || ''}
          onChange={handleSelectChange}
        >
          {sexOptions.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default UserSex;
