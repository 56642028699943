import React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';

const AccessToGym = ({ userPreferences, handleChange }) => {
  return (
    <div className="flex flex-col items-center justify-center text-center gap-2">
      <FormControlLabel
        control={
          <Checkbox
            name="fitness_plan.access_to_gym"
            checked={userPreferences.access_to_gym}
            onChange={handleChange}
          />
        }
        label="Do you have access to a gym?"
      />
    </div>
  );
};

export default AccessToGym;
