import React from "react";
import { Container, Typography, Button, Box } from "@mui/material";

export default function AcceptTermsBanner() {
  return (
    <section className="py-6">
      <Container>
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" p={4} mb={8}>
          <Typography variant="h2" align="center">Welcome to Thryv</Typography>
          <Typography variant="h6" align="center">Accept our terms and conditions to create plans.</Typography>
          <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} justifyContent="center" spacing={2}>
            <Button variant="contained" color="primary" href="/terms">
              Terms of Service
            </Button>
          </Box>
        </Box>
      </Container>
    </section>
  );
}
