import React from 'react';
import { Tabs, Tab, Button } from '@mui/material';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { useUserAccount } from '../../context/UserAccountContext';
import { useFlashMessage } from '../../context/FlashMessageContext';

// Form components
import PersonalInfoForm from './PersonalInfoForm';
import FitnessPlanForm from './FitnessPlanForm';

// Custom TabPanel component
const CustomTabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const UserAccount = () => {
  const [value, setValue] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const { userPreferences, setUserPreferences, updateUserAccountData, loading, errors } = useUserAccount();
  const { addFlashMessage } = useFlashMessage();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const [category, field] = name.split('.');

    if (type === 'checkbox') {
      if (['access_to_gym', 'pregnancy_status', 'diabetic'].includes(field)) {
        setUserPreferences(prevState => ({
          ...prevState,
          [category]: {
            ...prevState[category],
            [field]: checked
          }
        }));
      } else {
        const updatedList = checked
          ? [...userPreferences[category][field], value]
          : userPreferences[category][field].filter(item => item !== value);
        setUserPreferences(prevState => ({
          ...prevState,
          [category]: {
            ...prevState[category],
            [field]: updatedList
          }
        }));
      }
    } else {
      setUserPreferences(prevState => ({
        ...prevState,
        [category]: {
          ...prevState[category],
          [field]: value
        }
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateUserAccountData({
        personal_preferences: userPreferences.personal_info,
        fitness_preferences: userPreferences.fitness_plan,
        dietary_preferences: userPreferences.dietary_plan,
      });
      addFlashMessage('Account updated successfully');
    } catch (error) {
      console.error('Failed to update user account:', error);
      addFlashMessage('Failed to update user account', true);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="user-account-form-container mx-auto p-4 pt-12 flex flex-col min-h-screen">
      <form onSubmit={handleSubmit} className="">
        {errors && (
          <div className="alert alert-error bg-red-100 text-red-700 p-4 rounded">
            {errors.map((error, index) => (
              <div key={index}>{error}</div>
            ))}
          </div>
        )}

        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleTabChange} aria-label="User Account" centered>
            <Tab className='form-tab-item-container' label="Fitness Info" />
            <Tab className='form-tab-item-container' label="Personal Info" />
          </Tabs>
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'flex-end', m: 1 }}>
          <Button variant="contained" type="submit">
            Update Info
          </Button>
        </Box>

        <CustomTabPanel value={value} index={0}>
          <FitnessPlanForm
            userPreferences={userPreferences}
            handleChange={handleChange}
          />
        </CustomTabPanel>

        <CustomTabPanel value={value} index={1}>
          <PersonalInfoForm
            userPreferences={userPreferences}
            handleChange={handleChange}
          />
        </CustomTabPanel>
      </form>
    </div>
  );
};

export default UserAccount;
