// Toolpad Theme
import { createTheme } from '@mui/material/styles';

export const toolpadTheme = createTheme({
    cssVariables: {
      colorSchemeSelector: 'data-toolpad-color-scheme',
    },
    colorSchemes: {
      light: {
        palette: {
          primary: {
            main: '#d32f2f',
            light: '#ef5350',
            dark: '#b71c1c',
          },
          secondary: {
            main: '#424242',
            light: '#757575',
            dark: '#212121',
          },
          background: {
            default: '#fafafa',
            paper: '#ffffff',
          },
        },
      },
      dark: {
        palette: {
          primary: {
            main: '#ff4444',
            light: '#ff6659',
            dark: '#b71c1c',
          },
          secondary: {
            main: '#757575',
            light: '#9e9e9e',
            dark: '#424242',
          },
          background: {
            default: '#121212',
            paper: '#1e1e1e',
          },
        },
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 600,
        lg: 1200,
        xl: 1536,
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            transition: 'background-color 0.3s, color 0.3s',
          },
        },
      },
    },
  });