// Signup.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { useFlashMessage } from '../../context/FlashMessageContext';
import ReCAPTCHA from 'react-google-recaptcha';

// MUI Components
import {
  Box,
  Button,
  TextField,
  Typography
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useTheme } from '@mui/material/styles';

const Signup = () => {
  const theme = useTheme();
  const [form, setForm] = useState({
    email: '',
    password: '',
    passwordConfirmation: '',
  });
  const [errors, setErrors] = useState([]);
  const { handleSignup } = useAuth();
  const { addFlashMessage } = useFlashMessage();
  const navigate = useNavigate();
  const [recaptchaToken, setRecaptchaToken] = useState(null);

  const handleChange = (e) => {
    setForm((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleRecaptchaChange = (token) => {
    setRecaptchaToken(token);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors([]);
    
    if (!recaptchaToken) {
      setErrors(['Please complete the reCAPTCHA']);
      return;
    }
    
    try {
      const success = await handleSignup({
        email: form.email,
        password: form.password,
        password_confirmation: form.passwordConfirmation,
        recaptcha_token: recaptchaToken
      });
      
      if (success) {
        addFlashMessage('Welcome to Thryv! Please review our Terms of Service to start.', false);
        navigate('/terms');
      } else {
        const message = 'Signup failed. Please try again.';
        setErrors([message]);
        addFlashMessage(message, true);
      }
    } catch (error) {
      const message = error.response?.data?.message || 'Signup failed. Please try again.';
      setErrors([message]);
      addFlashMessage(message, true);
    }
  };

  return (
    <Box p={4} maxWidth="400px" mx="auto" mt={10}>
      <Typography sx={{ fontWeight: 'bold', color: theme.palette.primary.main }} variant="h5" align="center" gutterBottom>
        Create an account
      </Typography>

      <form onSubmit={handleSubmit}>
        <Grid container direction="column" spacing={3}>
          {errors.length > 0 && (
            <Grid item="true">
              <Box color="error.main" sx={{ textAlign: 'center' }}>
                {errors.map((err, idx) => (
                  <Typography key={idx} variant="body2">
                    {err}
                  </Typography>
                ))}
              </Box>
            </Grid>
          )}

          <Grid item="true">
            <TextField
              fullWidth
              type="email"
              name="email"
              label="Email"
              value={form.email}
              onChange={handleChange}
              required
            />
          </Grid>

          <Grid item="true">
            <TextField
              fullWidth
              type="password"
              name="password"
              label="Password"
              value={form.password}
              onChange={handleChange}
              required
            />
          </Grid>

          <Grid item="true">
            <TextField
              fullWidth
              type="password"
              name="passwordConfirmation"
              label="Confirm Password"
              value={form.passwordConfirmation}
              onChange={handleChange}
              required
            />
          </Grid>

          <Grid item="true">
            <Box 
              display="flex" 
              justifyContent="center" 
              my={2}
              sx={{
                backgroundColor: theme.palette.background.default,
              }}
            >
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                onChange={handleRecaptchaChange}
                theme={theme.palette.mode}
              />
            </Box>
          </Grid>

          <Grid item="true">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              sx={{
                bgcolor: '#d32f2f',
                '&:hover': {
                  bgcolor: '#b71c1c'
                },
                borderRadius: '25px',
                padding: '10px 0'
              }}
            >
              Sign Up
            </Button>
          </Grid>

          <Grid item="true">
            <Typography variant="body2" align="center">
              <Button
                component="a"
                href="/signin"
                color="primary"
                sx={{ textTransform: 'none' }}
              >
                Already have an account?
              </Button>
            </Typography>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default Signup;
