// src/api/auth.js
import API from '../api';

// TODO REMOVE LOGGING
export const signin = async (credentials) => {
  try {
    // Clear any existing auth state
    localStorage.removeItem('authToken');
    delete API.defaults.headers.common['Authorization'];

    const response = await API.post('/users/sign_in', { user: credentials });
    
    if (response.data.data?.token) {
      const token = response.data.data.token;
      
      // Set token in localStorage and headers
      localStorage.setItem('authToken', token);
      API.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      
      // Get all necessary data in parallel
      const [userResponse, userAccountResponse, fitnessPlansResponse] = await Promise.all([
        API.get('/users/user'),
        API.get('/user_account'),
        API.get('/user_account/fitness_plans')
      ]);

      return {
        status: response.data.status,
        data: {
          ...userResponse.data,
          user_account: userAccountResponse.data,
          fitness_plans: fitnessPlansResponse.data,
          token: token
        }
      };
    }
    throw new Error('Invalid signin response');
  } catch (error) {
    console.error('Signin error:', error);
    throw error;
  }
};

export const signup = async (credentials) => {
  try {
    const response = await API.post('/users', { user: credentials });
    if (response.data.data.token) {
      localStorage.setItem('authToken', response.data.data.token);
    }
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const signout = async () => {
  try {
    const token = localStorage.getItem('authToken');
    if (token) {
      await API.delete('/users/sign_out', {
        headers: { Authorization: `Bearer ${token}` }
      });
    }
  } catch (error) {
    console.error('Signout error:', error);
  } finally {
    // Clear all auth-related data
    localStorage.removeItem('authToken');
    delete API.defaults.headers.common['Authorization'];
    delete API.defaults.headers.common['Cache-Control'];
  }
};

export const getCurrentUser = async () => {
  const token = localStorage.getItem('authToken');
  if (!token) return null;

  try {
    // Get all necessary data in parallel
    const [userResponse, userAccountResponse, fitnessPlansResponse] = await Promise.all([
      API.get('/users/user'),
      API.get('/user_account'),
      API.get('/user_account/fitness_plans')
    ]);
    
    return {
      data: {
        ...userResponse.data,
        user_account: userAccountResponse.data,
        fitness_plans: fitnessPlansResponse.data
      }
    };
  } catch (error) {
    console.error('Error getting current user:', error);
    return null;
  }
};

export const sendPasswordResetInstructions = async (email) => {
  try {
    const response = await API.post('/users/password', {
      user: { email }
    });
    return response.data;
  } catch (error) {
    console.error('Error sending reset instructions:', error);
    throw error;
  }
};

export const resetPassword = async (resetToken, password, passwordConfirmation) => {
  try {
    const response = await API.put('/users/password', {
      user: {
        reset_password_token: resetToken,
        password,
        password_confirmation: passwordConfirmation
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error resetting password:', error);
    throw error;
  }
};
