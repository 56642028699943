import React from "react";
import { AppBar, Toolbar, Box, Button, Typography } from "@mui/material";
import { Link as MuiLink } from "@mui/material";

const LandingHeader = () => {
  return (
    <AppBar position="static" color="transparent" sx={{ boxShadow: 'none' }}>
      <Toolbar>
        <Box sx={{ flexGrow: 1 }}>
          <MuiLink href="/" underline="none">
            <Typography variant="h6" component="div" sx={{ flexGrow: 1, fontWeight: 'bold', fontSize: '1.5rem', color: 'primary.main' }}>
              Thryv Fitness
            </Typography>
          </MuiLink>
          {/* <img src="/logo.png" alt="logo" /> */}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default React.memo(LandingHeader);