import React from "react";
import { 
  Typography, 
  Box 
} from '@mui/material';
import { 
  Timeline, 
  TimelineItem, 
  TimelineSeparator, 
  TimelineConnector, 
  TimelineContent, 
  TimelineDot 
} from '@mui/lab';
import FitnessEvent from './FitnessEvent';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';

const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const dayNameToIndex = {
    'Sunday': 0,
    'Monday': 1,
    'Tuesday': 2,
    'Wednesday': 3,
    'Thursday': 4,
    'Friday': 5,
    'Saturday': 6,
};

export default function WeekTimeline({ fitnessPlan, dietaryPlan, planId }) {
    const today = new Date();
    const currentDayIndex = today.getDay();

    // Extract fitness events
    const fitnessEventsByDay = {};
    if (fitnessPlan && fitnessPlan.fitness_plan && fitnessPlan.fitness_plan.workouts) {
        fitnessPlan.fitness_plan.workouts.forEach(workout => {
            const dayIndex = dayNameToIndex[workout.day];
            fitnessEventsByDay[dayIndex] = workout;
        });
    }

    return (
        <Timeline
            position="right"
            sx={{
                [`& .MuiTimelineItem-root:before`]: {
                    flex: 0,
                    padding: 0
                }
            }}
        >
            {daysOfWeek.map((day, dayIndex) => {
                const fitnessEvent = fitnessEventsByDay[dayIndex];
                const isToday = dayIndex === currentDayIndex;

                return (
                    <TimelineItem key={dayIndex}>
                        <TimelineSeparator>
                            <TimelineDot 
                                color={isToday ? "primary" : "grey"}
                                variant={isToday ? "filled" : "outlined"}
                            />
                            {dayIndex < daysOfWeek.length - 1 && <TimelineConnector />}
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <Typography 
                                    variant="h6" 
                                    component="span"
                                    color={isToday ? "primary" : "text.primary"}
                                >
                                    {day}
                                </Typography>
                                {isToday && (
                                    <ArrowLeftIcon color="primary" />
                                )}
                            </Box>
                            {fitnessEvent && (
                                <Box sx={{ mt: 1 }}>
                                    <FitnessEvent 
                                        workout={fitnessEvent} 
                                        modalKey={dayIndex} 
                                        planId={planId} 
                                    />
                                </Box>
                            )}
                        </TimelineContent>
                    </TimelineItem>
                );
            })}
        </Timeline>
    );
}