import React from 'react';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import Grid from '@mui/material/Grid2';

const feetOptions = Array.from({ length: 8 }, (_, i) => ({ value: i + 1, label: `${i + 1}` }));
const inchOptions = Array.from({ length: 12 }, (_, i) => ({ value: i, label: `${i}` }));

const UserHeight = ({ userPreferences, handleChange }) => {
  const handleFeetChange = (event) => {
    const feet = event.target.value;
    const inches = userPreferences.user_height ? userPreferences.user_height.split(' ')[2] : '0'; // Default to 0 if not set
    handleChange({
      target: {
        name: 'personal_info.user_height',
        value: `${feet} feet ${inches} inches`
      }
    });
  };

  const handleInchesChange = (event) => {
    const inches = event.target.value;
    const feet = userPreferences.user_height ? userPreferences.user_height.split(' ')[0] : '0'; // Default to 0 if not set
    handleChange({
      target: {
        name: 'personal_info.user_height',
        value: `${feet} feet ${inches} inches`
      }
    });
  };

  return (
    <Grid 
      container 
      spacing={1} 
      sx={{ 
        width: '100%',
        display: 'flex',
        flexDirection: 'row'
      }}
    >
      <Grid xs={6} sx={{ flex: 1 }}>
        <FormControl sx={{ width: '100%', minWidth: '120px' }}>
          <InputLabel id="feet_label">Feet</InputLabel>
          <Select
            id="feet"
            name="feet"
            label="Feet"
            value={userPreferences.user_height ? userPreferences.user_height.split(' ')[0] : ''}
            onChange={handleFeetChange}
          >
            {feetOptions.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid xs={6} sx={{ flex: 1 }}>
        <FormControl sx={{ width: '100%', minWidth: '120px' }}>
          <InputLabel id="inches_label">Inches</InputLabel>
          <Select
            id="inches"
            name="inches"
            label="Inches"
            value={userPreferences.user_height ? userPreferences.user_height.split(' ')[2] : ''}
            onChange={handleInchesChange}
          >
            {inchOptions.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default UserHeight;
