import React, { useState } from 'react';
import { TextField, Button, Typography, Box } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useTheme } from '@mui/material/styles';
import { sendPasswordResetInstructions } from '../../api/auth';

export default function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState({ type: '', content: '' });
  const theme = useTheme();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = await sendPasswordResetInstructions(email);
      setMessage({
        type: 'success',
        content: 'Reset password instructions have been sent to your email.'
      });
    } catch (error) {
      setMessage({
        type: 'error',
        content: error.response?.data?.message || 'An error occurred. Please try again.'
      });
    }
  };

  return (
    <Box p={4} maxWidth="400px" mx="auto" mt={10}>
      <Typography sx={{ fontWeight: 'bold', color: theme.palette.primary.main }} variant="h5" align="center" gutterBottom>
        Forgot Password
      </Typography>

      {message.content && (
        <Box color={message.type === 'error' ? 'error.main' : 'success.main'} sx={{ textAlign: 'center', mb: 2 }}>
          <Typography variant="body2">{message.content}</Typography>
        </Box>
      )}

      <form onSubmit={handleSubmit}>
        <Grid container direction="column" spacing={3}>
          <Grid item="true">
            <TextField
              fullWidth
              type="email"
              name="email"
              label="Email Address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </Grid>

          <Grid item="true">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              sx={{
                bgcolor: '#d32f2f',
                '&:hover': {
                  bgcolor: '#b71c1c'
                },
                borderRadius: '25px',
                padding: '10px 0'
              }}
            >
              Send Reset Instructions
            </Button>
          </Grid>

          <Grid item="true">
            <Typography variant="body2" align="center">
              <Button
                component="a"
                href="/signin"
                color="primary"
                sx={{ textTransform: 'none' }}
              >
                Sign in to your account here
              </Button>
            </Typography>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
}