import React from 'react';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';

const activityLevelOptions = [
  { value: 'Sedentary', label: 'Sedentary' },
  { value: 'Lightly Active', label: 'Lightly Active' },
  { value: 'Moderately Active', label: 'Moderately Active' },
  { value: 'Very Active', label: 'Very Active' },
  { value: 'Super Active', label: 'Super Active' }
];

const ActivityLevel = ({ userPreferences, handleChange }) => {
  const handleSelectChange = (event) => {
    handleChange({
      target: {
        name: 'fitness_plan.activity_level',
        value: event.target.value
      }
    });
  };

  return (
    <div className="max-w-md">
      <FormControl fullWidth>
        <InputLabel id="activity_level_label">Activity Level</InputLabel>
        <Select
          labelId="activity_level_label"
          id="activity_level"
          name="fitness_plan.activity_level"
          label="Activity Level"
          value={userPreferences.activity_level || ''}
          onChange={handleSelectChange}
        >
          {activityLevelOptions.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default ActivityLevel;
