import DashboardIcon from '@mui/icons-material/Dashboard';
import TuneIcon from '@mui/icons-material/Tune';
import ListIcon from '@mui/icons-material/List';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

export const NAVIGATION = (isAdmin) => [
    {
      segment: '',
      title: 'Dashboard',
      path: '/',
      icon: <DashboardIcon />,
    },
    {
      segment: 'fitness-preferences',
      title: 'Fitness Preferences',
      path: '/fitness-preferences',
      icon: <TuneIcon />,
    },
    {
      segment: 'plans',
      title: 'All Plans',
      path: '/plans',
      icon: <ListIcon />,
    },
    ...(isAdmin ? [{
      segment: 'admin',
      title: 'Admin',
      path: '/admin',
      icon: <AdminPanelSettingsIcon />,
    }] : []),
  ];
