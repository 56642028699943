import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { signin, signup, signout, getCurrentUser } from '../api/auth';
import { jwtDecode } from 'jwt-decode';
import API from '../api';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [authState, setAuthState] = useState({
    user: null,
    isAuthenticated: false,
    fitnessPlans: null
  });
  const [isInitializing, setIsInitializing] = useState(true);

  const setUser = useCallback((userData) => {
    setAuthState(prev => ({
      ...prev,
      user: userData,
      isAuthenticated: !!userData
    }));
  }, []);

  const handleSignin = useCallback(async (credentials) => {
    try {
      const response = await signin(credentials);
      
      if (response?.status?.code === 200) {
        const userData = response.data;
        
        setAuthState({
          user: userData,
          fitnessPlans: userData.fitness_plans,
          isAuthenticated: true
        });
        
        return response;
      }
    } catch (error) {
      setAuthState({
        user: null,
        fitnessPlans: null,
        isAuthenticated: false
      });
      throw error;
    }
  }, []);

  const handleSignup = useCallback(async (credentials) => {
    try {
      const response = await signup(credentials);
      return response.status.code === 200;
    } catch (error) {
      console.error('Signup error:', error);
      return false;
    }
  }, []);

  const handleSignout = useCallback(async () => {
    try {
      await signout();
      setAuthState(prev => ({
        ...prev,
        isAuthenticated: false,
        user: null
      }));
      localStorage.removeItem('authToken');
      delete API.defaults.headers.common['Authorization'];
    } finally {
      navigate('/');
    }
  }, [navigate]);

  const checkTokenValidity = useCallback(() => {
    const token = localStorage.getItem('authToken');
    if (!token) {
      setAuthState(prev => ({
        ...prev,
        isAuthenticated: false
      }));
      return;
    }

    try {
      const decoded = jwtDecode(token);
      const isValid = decoded.exp > Date.now() / 1000;
      setAuthState(prev => ({
        ...prev,
        isAuthenticated: isValid
      }));
      if (!isValid) {
        handleSignout();
      }
    } catch (error) {
      console.error('Token validation error:', error);
      handleSignout();
    }
  }, [handleSignout]);

  useEffect(() => {
    checkTokenValidity();
    const interval = setInterval(checkTokenValidity, 60000);
    return () => clearInterval(interval);
  }, [checkTokenValidity]);

  useEffect(() => {
    const initAuth = async () => {
      try {
        const token = localStorage.getItem('authToken');
        if (!token) {
          setIsInitializing(false);
          return;
        }

        API.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        const response = await getCurrentUser();
        
        if (response?.data) {
          setAuthState({
            user: response.data,
            fitnessPlans: response.data.fitness_plans,
            isAuthenticated: true
          });
        }
      } catch (error) {
        console.error('Auth initialization error:', error);
        localStorage.removeItem('authToken');
        delete API.defaults.headers.common['Authorization'];
        setAuthState({
          user: null,
          fitnessPlans: null,
          isAuthenticated: false
        });
      } finally {
        setIsInitializing(false);
      }
    };

    initAuth();
  }, []);

  return (
    <AuthContext.Provider 
      value={{
        ...authState,
        handleSignin,
        handleSignup,
        handleSignout,
        setUser,
        isLoading: isInitializing
      }}
    >
      {!isInitializing ? children : null}
    </AuthContext.Provider>
  );
};
