import React, { useState, useEffect } from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const options = [
  { value: '15', label: '15 Minutes' },
  { value: '30', label: '30 Minutes' },
  { value: '45', label: '45 Minutes' },
  { value: '60', label: '60 Minutes' },
  { value: '90', label: '90 Minutes' },
  { value: '120', label: '120 Minutes' }
];

const AmountOfTime = ({ userPreferences, handleChange }) => {
  const [selectedDuration, setSelectedDuration] = useState('');

  useEffect(() => {
    setSelectedDuration(userPreferences.amount_of_time);
  }, [userPreferences.amount_of_time]);

  const handleSelectChange = (event) => {
    const value = event.target.value;
    setSelectedDuration(value);
    handleChange({
      target: {
        name: 'fitness_plan.amount_of_time',
        value: value
      }
    });
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="amount_of_time">Select Workout Duration</InputLabel>
      <Select
        id="amount_of_time"
        name="fitness_plan.amount_of_time"
        value={selectedDuration}
        onChange={handleSelectChange}
        label="Select Workout Duration"
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default AmountOfTime;
