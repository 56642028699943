import React from 'react';
import { Container, Typography, Divider, Box, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import TodaysFitnessPlan from './TodaysFitnessPlan';
import { Link } from '@mui/material';
import TodayIcon from '@mui/icons-material/Today';
import IconButton from '@mui/material/IconButton';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
const daysOfWeek = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

const HomePage = ({ user, fitnessPlan }) => {
  const theme = useTheme();
  const today = new Date();
  const [currentDayIndex, setCurrentDayIndex] = React.useState(today.getDay());

  const handlePreviousDay = () => {
    setCurrentDayIndex((prevIndex) => (prevIndex === 0 ? 6 : prevIndex - 1));
  };

  const handleNextDay = () => {
    setCurrentDayIndex((prevIndex) => (prevIndex === 6 ? 0 : prevIndex + 1));
  };

  const handleToday = () => {
    setCurrentDayIndex(today.getDay());
  };

  const currentDay = daysOfWeek[currentDayIndex];
  const isToday = currentDayIndex === today.getDay();

  // Access workouts through the nested fitness_plan object
  const todayWorkout = fitnessPlan?.fitness_plan?.workouts?.find(
    (workout) => workout?.day?.toLowerCase() === currentDay?.toLowerCase()
  ) || null;

  return (
    <Container>
      <Box>
        {/* Row 1: Hello User */}
        <Box sx={{ mb: 0, mt: 1 }}>
          <Typography variant="h5">
            Hello {user?.first_name || user?.email},
          </Typography>
        </Box>

        {/* Row 2: Day Information */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            mb: 1,
          }}
        >
          <Typography variant="h5">
            {isToday ? "Today is" : "Showing"}{' '}
            <Box
              component="span"
              sx={{
                color: theme.palette.primary.main,
                fontWeight: 'bold',
                fontSize: '1.8rem',
              }}
            >
              {currentDay}
              {!isToday && (
                <IconButton size="small" variant="text" onClick={handleToday} title="Today">
                  <TodayIcon />
                </IconButton>
              )}
            </Box>
          </Typography>
        </Box>

        {/* Row 3: Buttons */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            gap: 1,
            mb: 2,
          }}
        >
          <Button
            size="small"
            variant="contained"
            onClick={handlePreviousDay}
            startIcon={<ArrowBackIosIcon />}
          >
            Previous Day
          </Button>
          <Button
            size="small"
            variant="outlined"
            onClick={handleNextDay}
            endIcon={<ArrowForwardIosIcon />}
          >
            Next Day
          </Button>
        </Box>

        {/* Divider */}
        <Divider sx={{ mb: 4 }} />

        {/* Fitness Plan or Prompt */}
        {fitnessPlan === undefined ? (
          <Box sx={{ mt: 10, mb: 1, textAlign: 'center' }}>
            <Typography variant="h6" color="text.secondary">
              Get started by filling out your <Link href="/fitness-preferences">preferences!</Link>
            </Typography>
          </Box>
        ) : todayWorkout ? (
          <TodaysFitnessPlan workout={todayWorkout} />
        ) : (
          <Box sx={{ mt: 10, mb: 1, textAlign: 'center' }}>
            <Typography variant="h6" color="text.secondary">
              It looks like you have a rest day today!
            </Typography>
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default HomePage;