import React from 'react';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';

const fitnessGoalOptions = [
  { value: '', label: '' },
  { value: 'Lose Weight', label: 'Lose Weight' },
  { value: 'Build Muscle', label: 'Build Muscle' },
  { value: 'Build Flexibility', label: 'Build Flexibility' },
  { value: 'Build Strength', label: 'Build Strength' },
  { value: 'Improve Endurance', label: 'Improve Endurance' }
];

const FitnessGoals = ({ userPreferences, handleChange }) => {
  const handleSelectChange = (event) => {
    handleChange({
      target: {
        name: 'fitness_plan.fitness_goals',
        value: event.target.value
      }
    });
  };

  return (
    <div className="max-w-md">
      <FormControl fullWidth>
        <InputLabel id="fitness_goals_label">Fitness Goals</InputLabel>
        <Select
          labelId="fitness_goals_label"
          id="fitness_goals"
          name="fitness_plan.fitness_goals"
          label="Fitness Goals"
          value={userPreferences.fitness_goals || ''}
          onChange={handleSelectChange}
        >
          {fitnessGoalOptions.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default FitnessGoals;
