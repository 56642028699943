import React, { useState, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';
import { useFlashMessage } from '../../context/FlashMessageContext';
import API from '../../api';

// MUI Components
import Grid from '@mui/material/Grid2';
import {
  Box,
  Button,
  TextField,
  Avatar,
  Typography,
} from '@mui/material';

const UserProfile = () => {
  const { user, setUser } = useAuth();
  const { addFlashMessage } = useFlashMessage();
  const [form, setForm] = useState({
    first_name: '',
    last_name: '',
    password: '',
    password_confirmation: '',
  });
  const [avatar, setAvatar] = useState(null);
  const [avatarPreview, setAvatarPreview] = useState(null);

  useEffect(() => {
    if (user) {
      setForm(prev => ({
        ...prev,
        first_name: user.first_name || '',
        last_name: user.last_name || '',
      }));
      if (user.avatar_url) {
        setAvatarPreview(user.avatar_url);
      }
    }
  }, [user]);

  useEffect(() => {
    const refreshUser = async () => {
      try {
        const response = await API.get('/users/user');
        if (response.data.user) {
          setUser(response.data.user);
        }
      } catch (error) {
        console.error('Error refreshing user data:', error);
      }
    };

    if (user && user.avatar_url) {
      const interval = setInterval(refreshUser, 6 * 24 * 60 * 60 * 1000);
      return () => clearInterval(interval);
    }
  }, [user?.id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm(prev => ({ ...prev, [name]: value }));
  };

  const handleAvatarChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setAvatar(file);
      setAvatarPreview(URL.createObjectURL(file));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    // Only append non-empty values
    if (form.first_name) formData.append('first_name', form.first_name);
    if (form.last_name) formData.append('last_name', form.last_name);
    if (form.password) formData.append('password', form.password);
    if (form.password_confirmation) formData.append('password_confirmation', form.password_confirmation);
    if (avatar) formData.append('avatar', avatar);

    try {
      // Get token from localStorage instead of user object
      const token = localStorage.getItem('authToken');
      
      const response = await API.patch('/users/user', formData, {
        headers: { 
          'Content-Type': 'multipart/form-data',
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.data.user) {
        setUser({
          ...response.data.user,
          token // Preserve the token in the user object
        });
        addFlashMessage('Profile updated successfully', false);
        
        // Clear password fields and avatar state after successful update
        setForm(prev => ({ 
          ...prev, 
          password: '', 
          password_confirmation: '' 
        }));
        setAvatar(null);
      } else {
        throw new Error('Invalid response format');
      }
    } catch (error) {
      console.error('Update error:', error);
      
      // Handle 401 errors specifically
      if (error.response?.status === 401) {
        addFlashMessage('Your session has expired. Please sign in again.', 'error');
        // Optionally redirect to signin page
        window.location.href = '/signin';
        return;
      }
      
      addFlashMessage(
        error.response?.data?.errors?.join(', ') || 
        error.message || 
        'Update failed', 
        'error'
      );
    }
  };

  return (
    <Box p={3} maxWidth="600px" mx="auto" bgcolor="transparent">
      <Typography variant="h5" gutterBottom>
        Profile Settings
      </Typography>
      
      <form onSubmit={handleSubmit}>
        <Grid container direction="column" spacing={2}>
          {/* Avatar only */}
          <Grid item="true">
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', maxWidth: '200px' }}>
              <Avatar
                src={avatarPreview}
                sx={{ width: 150, height: 150, mb: 2 }}
              />
              <Button
                variant="outlined"
                component="label"
                color="error"
                sx={{ 
                  border: '1px solid #d32f2f',
                  color: '#d32f2f',
                  width: '100%'
                }}
              >
                UPLOAD AVATAR
                <input
                  type="file"
                  hidden
                  accept="image/*"
                  onChange={handleAvatarChange}
                />
              </Button>
            </Box>
          </Grid>

          {/* Name */}
          <Grid container spacing={2}>
            <Grid item="true" size={{ xs: 12, md: 6 }}>
              <TextField
                fullWidth
                label="First Name"
                name="first_name"
                value={form.first_name}
                onChange={handleChange}
              />
            </Grid>
            <Grid item="true" size={{ xs: 12, md: 6 }}>
              <TextField
                fullWidth
                label="Last Name"
                name="last_name"
                value={form.last_name}
                onChange={handleChange}
              />
            </Grid>
          </Grid>

          {/* New Password */}
          <Grid container spacing={2}>
            <Grid item="true" size={{ xs: 12, md: 6 }}>
              <TextField
                fullWidth
                type="password"
                label="New Password"
                name="password"
                value={form.password}
                onChange={handleChange}
              />
            </Grid>
            <Grid item="true" size={{ xs: 12, md: 6 }}>
              <TextField
                fullWidth
                type="password"
                label="Confirm New Password"
                name="password_confirmation"
                value={form.password_confirmation}
                onChange={handleChange}
              />
            </Grid>
          </Grid>

          {/* Submit Button */}
          <Grid container spacing={2}>
            <Button
              type="submit"
              variant="contained"
              sx={{
                bgcolor: '#d32f2f',
                '&:hover': {
                  bgcolor: '#b71c1c'
                },
                borderRadius: '25px',
                padding: '10px 0'
              }}
              fullWidth
            >
              UPDATE PROFILE
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default UserProfile; 