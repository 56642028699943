import React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import { Label } from "flowbite-react";

const PregnancyStatus = ({ userPreferences, handleChange }) => {
  return (
    <div className="flex flex-col items-center justify-center text-center gap-2">
      <Label>Are you currently pregnant? </Label>
      <FormControlLabel
        control={
          <Checkbox
            name="personal_info.pregnancy_status"
            checked={userPreferences.pregnancy_status}
            onChange={handleChange}
          />
        }
        label="Yes"
      />
    </div>
  );
};

export default PregnancyStatus;
