import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';

// MUI Components
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';

// Toolpad Components
import {
  Account,
  AccountPopoverFooter,
  SignOutButton,
} from '@toolpad/core/Account';

function SidebarFooterAccountPopover() {
  const { user, handleSignout } = useAuth();
  const navigate = useNavigate();
  
  const handleProfileClick = () => {
    navigate('/profile');
  };
  
  return (
    <Stack direction="column">
      <Typography variant="body2" mx={2} mt={1}>
        Account Settings
      </Typography>
      <MenuList>
        <MenuItem
          onClick={handleProfileClick}
          sx={{
            justifyContent: 'flex-start',
            width: '100%',
            columnGap: 2,
          }}
        >
          <ListItemIcon>
            {user?.avatar_url ? (
              <Avatar
                src={user.avatar_url}
                sx={{
                  width: 32,
                  height: 32,
                }}
              />
            ) : (
              <Avatar
                sx={{
                  width: 32,
                  height: 32,
                  fontSize: '0.95rem',
                }}
              >
                {user?.email?.[0]?.toUpperCase()}
              </Avatar>
            )}
          </ListItemIcon>
          <ListItemText
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              width: '100%',
            }}
            primary={user?.first_name && user?.last_name ? 
              `${user.first_name} ${user.last_name}` : 
              user?.email}
            secondary="View Profile"
            primaryTypographyProps={{ variant: 'body2' }}
            secondaryTypographyProps={{ variant: 'caption' }}
          />
        </MenuItem>
      </MenuList>
      <Divider />
      <AccountPopoverFooter>
        <SignOutButton onClick={handleSignout} />
      </AccountPopoverFooter>
    </Stack>
  );
}

function AccountSidebarPreview({ handleClick, open, mini }) {
  const { user } = useAuth();

  const avatarComponent = user?.avatar_url ? (
    <Avatar
      src={user.avatar_url}
      sx={{
        width: mini ? 40 : 44,
        height: mini ? 40 : 44,
        left: mini ? 0 : 10,
      }}
    />
  ) : (
    <Avatar
      sx={{
        width: mini ? 32 : 40,
        height: mini ? 32 : 40,
        fontSize: mini ? '0.95rem' : '1.1rem',
      }}
    >
      {user?.email?.[0]?.toUpperCase()}
    </Avatar>
  );

  return (
    <Stack direction="column" p={0} overflow="hidden">
      <Divider />
      <Box
        onClick={handleClick}
        sx={{
          p: '12px 0',
          margin: mini ? '0 auto' : '0',
          cursor: 'pointer',
          '&:hover': { bgcolor: 'action.hover' },
          display: 'flex',
          alignItems: 'center',
          gap: 2,
        }}
      >
        {avatarComponent}
        {!mini && (
          <Stack spacing={0}>
            <Typography variant="body2">
              {user?.first_name && user?.last_name ? 
                `${user.first_name} ${user.last_name}` : 
                user?.email}
            </Typography>
            <Typography variant="caption" color="text.secondary">
              {user?.email}
            </Typography>
          </Stack>
        )}
      </Box>
    </Stack>
  );
}

export function SidebarFooterAccount({ mini }) {
  const PreviewComponent = React.useMemo(
    () => (props) => <AccountSidebarPreview {...props} mini={mini} />,
    [mini]
  );

  return (
    <Account
      slots={{
        preview: PreviewComponent,
        popoverContent: SidebarFooterAccountPopover,
      }}
      slotProps={{
        popover: {
          transformOrigin: { horizontal: 'left', vertical: 'bottom' },
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
          disableAutoFocus: true,
          slotProps: {
            paper: {
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: (theme) =>
                  `drop-shadow(0px 2px 8px ${theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.10)' : 'rgba(0,0,0,0.32)'})`,
                mt: 1,
                '&::before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  bottom: 10,
                  left: 0,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translate(-50%, -50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            },
          },
        },
      }}
      sx={{
        maxHeight: '100vh',
        overflowY: 'auto',
      }}
    />
  );
}