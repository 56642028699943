import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const workoutPreferenceOptions = [
  { value: 'Strength Training', label: 'Strength Training' },
  { value: 'Cardio', label: 'Cardio' },
  { value: 'HIIT', label: 'HIIT' },
  { value: 'Yoga', label: 'Yoga' },
  { value: 'Pilates', label: 'Pilates' },
  { value: 'CrossFit', label: 'CrossFit' },
  { value: 'Cycling', label: 'Cycling' },
  { value: 'Running', label: 'Running' },
  { value: 'Bodyweight Exercises', label: 'Bodyweight Exercises' },
  { value: 'Calisthenics', label: 'Calisthenics' },
  { value: 'Stretching', label: 'Stretching' },
  { value: 'Dance Fitness', label: 'Dance Fitness' },
  { value: 'Functional Training', label: 'Functional Training' },
  { value: 'Walking', label: 'Walking' }
];

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const WorkoutPreferences = ({ userPreferences, handleChange }) => {
  const handleSelectChange = (event, value) => {
    handleChange({
      target: {
        name: 'fitness_plan.workout_preferences',
        value: value.map(preference => preference.value)
      }
    });
  };

  return (
    <div className="max-w-md">
      <Autocomplete
        multiple
        id="workout_preferences"
        options={workoutPreferenceOptions}
        disableCloseOnSelect
        getOptionLabel={(option) => option.label}
        renderOption={(props, option, { selected }) => {
          const { key, ...optionProps } = props;
          return (
            <li key={key} {...optionProps}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.label}
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField {...params} label="Workout Preferences" placeholder="Select Preferences" />
        )}
        onChange={handleSelectChange}
        value={workoutPreferenceOptions.filter(option => userPreferences.workout_preferences.includes(option.value))}
      />
    </div>
  );
};

export default WorkoutPreferences;
