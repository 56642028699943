// src/components/Auth/Signin.js
import React, { useState } from 'react';

// React Router
import { useNavigate } from 'react-router-dom';

// Contexts
import { useAuth } from '../../context/AuthContext';
import { useFlashMessage } from '../../context/FlashMessageContext';

// MUI Components
import {
  Box,
  Button,
  TextField,
  Typography
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useTheme } from '@mui/material/styles';

const Signin = () => {
  const [form, setForm] = useState({ email: '', password: '' });
  const [errors, setErrors] = useState([]);
  const { handleSignin } = useAuth();
  const { addFlashMessage } = useFlashMessage();
  const navigate = useNavigate();
  const theme = useTheme();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm(prev => ({ ...prev, [name]: value }));
  };

  // TODO REMOVE LOGGING
  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors([]);
    
    try {
      const response = await handleSignin(form);
      
      if (response?.status?.code === 200) {
        addFlashMessage('Successfully signed in!', false);
        navigate('/');
      }
    } catch (error) {
      console.log('Signin error:', error);
      const message = error.response?.data?.error || 
                     (error.response?.status === 401 ? 'Invalid email or password' : 'Sign in failed');
      console.log('Error message to display:', message);
      setErrors([message]);
      addFlashMessage(message, true);
    }
  };

  return (
    <Box p={4} maxWidth="400px" mx="auto" mt={10}>
      <Typography sx={{ fontWeight: 'bold', color: theme.palette.primary.main }} variant="h5" align="center" gutterBottom>
        Sign in to account
      </Typography>

      <form onSubmit={handleSubmit}>
        <Grid container direction="column" spacing={3}>
          {errors.length > 0 && (
            <Grid item="true">
              <Box color="error.main" sx={{ textAlign: 'center' }}>
                {errors.map((err, idx) => (
                  <Typography key={idx} variant="body2">
                    {err}
                  </Typography>
                ))}
              </Box>
            </Grid>
          )}

          <Grid item="true">
            <TextField
              fullWidth
              type="email"
              name="email"
              label="Email"
              value={form.email}
              onChange={handleChange}
              required
            />
          </Grid>

          <Grid item="true">
            <TextField
              fullWidth
              type="password"
              name="password"
              label="Password"
              value={form.password}
              onChange={handleChange}
              required
            />
          </Grid>

          <Grid item="true">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              sx={{
                bgcolor: '#d32f2f',
                '&:hover': {
                  bgcolor: '#b71c1c'
                },
                borderRadius: '25px',
                padding: '10px 0'
              }}
            >
              Sign In
            </Button>
          </Grid>

          <Grid item="true">
            <Typography variant="body2" align="center">
              Don't have an account yet?{' '}
              <Button
                component="a"
                href="/signup"
                color="primary"
                sx={{ textTransform: 'none' }}
              >
                Sign up
              </Button>
            </Typography>
          </Grid>

          <Grid item="true">
            <Box display="flex" justifyContent="center">
              <Button
                component="a"
                href="/forgot-password"
                color="primary"
                sx={{ 
                  textTransform: 'none',
                  fontSize: '0.875rem',
                  padding: '0'
                }}
              >
                Forgot Password?
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default Signin;
