import React from 'react';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';

const UserWeight = ({ userPreferences, handleChange }) => {
  const weightOptions = Array.from({ length: 351 }, (_, i) => ({
    value: i + 50,
    label: `${i + 50}`
  }));

  const handleWeightChange = (event) => {
    handleChange({
      target: {
        name: 'personal_info.user_weight',
        value: event.target.value
      }
    });
  };

  return (
    <div className="max-w-md">
      <FormControl fullWidth>
        <InputLabel id="weight_label">Weight</InputLabel>
        <Select
          id="user_weight"
          name="personal_info.user_weight"
          label="Weight"
          value={userPreferences.user_weight || ''}
          onChange={handleWeightChange}
        >
          {weightOptions.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default UserWeight;
