import React from 'react';
import { 
  Paper,
  Typography,
  Box
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import PersonalPreferencesDisplay from './PersonalPreferencesDisplay';
import FitnessPreferencesDisplay from './FitnessPreferencesDisplay';

const CombinedPreferencesDisplay = ({ personalPreferences, fitnessPreferences }) => {
  if (!personalPreferences && !fitnessPreferences) return null;

  return (
    <Paper sx={{ p: 3, width: '100%' }}>
      <Box sx={{ width: '100%' }}>
        <Grid container spacing={3}>
          <Grid xs={12} md={6}>
            <Typography variant="h6" color="primary" gutterBottom>
              Personal Details
            </Typography>
            <PersonalPreferencesDisplay preferences={personalPreferences} />
          </Grid>
          
          <Grid xs={12} md={6} sx={{ 
            borderLeft: { xs: 'none', md: 1 }, 
            borderTop: { xs: 1, md: 'none' },
            borderColor: 'divider',
            pt: { xs: 3, md: 0 },
            pl: { xs: 0, md: 3 }
          }}>
            <Typography variant="h6" color="primary" gutterBottom>
              Fitness Details
            </Typography>
            <FitnessPreferencesDisplay preferences={fitnessPreferences} />
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default CombinedPreferencesDisplay; 