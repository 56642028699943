import React, { createContext, useState, useContext, useEffect } from 'react';
import { fetchUserAccount, updateUserAccount } from '../api/userAccount';

const UserAccountContext = createContext();

export const useUserAccount = () => useContext(UserAccountContext);

export const UserAccountProvider = ({ children }) => {
	const [userAccount, setUserAccount] = useState({});
  const [userTermsAccepted, setUserTermsAccepted] = useState(false);
  const [userPreferences, setUserPreferences] = useState({
    personal_info: {
      user_sex: '',
      user_age: '',
      user_height: '',
      user_weight: '',
      fitness_goals: '',
      activity_level: '',
      disabilities: [],
      pregnancy_status: false,
      user_bmi: ''
    },
    fitness_plan: {
      fitness_level: '',
      amount_of_time: '',
      workout_days: [],
      injuries: [],
      available_equipment: [],
      workout_preferences: [],
      preferred_workout_equipment: [],
      blacklisted_workout_equipment: [],
      access_to_gym: false
    },
    dietary_plan: {
      personal_diets: [],
      allergies: [],
      food_preferences: [],
      diabetic: false
    }
  });
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState(null);

  useEffect(() => {
    const getUserAccount = async () => {
      try {
        const token = localStorage.getItem('authToken');
        if (!token) {
          throw new Error('No authentication token found');
        }
        const data = await fetchUserAccount();
				setUserAccount(data);
        setUserTermsAccepted(data.terms_accepted);
        setUserPreferences({
          personal_info: {
            user_sex: data.personal_preferences.user_sex || '',
            user_age: data.personal_preferences.user_age || '',
            user_height: data.personal_preferences.user_height || '',
            user_weight: data.personal_preferences.user_weight || '',
            injuries: data.personal_preferences.injuries || [],
            disabilities: data.personal_preferences.disabilities || [],
            pregnancy_status: data.personal_preferences.pregnancy_status || false,
            user_bmi: data.personal_preferences.user_bmi || ''
          },
          fitness_plan: {
            fitness_level: data.fitness_preferences.fitness_level || '',
            fitness_goals: data.fitness_preferences.fitness_goals || '',
            activity_level: data.fitness_preferences.activity_level || '',
            workout_days: data.fitness_preferences.workout_days || [],
            amount_of_time: data.fitness_preferences.amount_of_time || '',
            available_equipment: data.fitness_preferences.available_equipment || [],
            workout_preferences: data.fitness_preferences.workout_preferences || [],
            preferred_workout_equipment: data.fitness_preferences.preferred_workout_equipment || [],
            blacklisted_workout_equipment: data.fitness_preferences.blacklisted_workout_equipment || [],
            access_to_gym: data.fitness_preferences.access_to_gym || false
          },
          dietary_plan: {
            personal_diets: data.dietary_preferences.personal_diets || [],
            allergies: data.dietary_preferences.allergies || [],
            food_preferences: data.dietary_preferences.food_preferences || [],
            diabetic: data.dietary_preferences.diabetic || false
          }
        });
      } catch (error) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('Couldn\'t fetch user account:', error);
        }
      } finally {
        setLoading(false);
      }
    };

    getUserAccount();
  }, []);

  const updateUserAccountData = async (updatedData) => {
    try {
      await updateUserAccount(updatedData);
      setUserPreferences((prev) => ({
        ...prev,
        ...updatedData
      }));
    } catch (error) {
      console.error('Failed to update user account:', error);
      setErrors(['Failed to update user account']);
    }
  };

  return (
    <UserAccountContext.Provider value={{ userAccount, userTermsAccepted, userPreferences, setUserPreferences, updateUserAccountData, loading, errors }}>
      {children}
    </UserAccountContext.Provider>
  );
};
