import API from '../api';

export const createFitnessPlan = async (fitnessPlan) => {
  const token = localStorage.getItem('authToken');
  if (!token) {
    throw new Error('No authentication token found');
  }

  try {
    const response = await API.post('/user_account/fitness_plans', fitnessPlan, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error creating fitness plan:', error);
    throw error;
  }
};

export const fetchFitnessPlans = async () => {
  const token = localStorage.getItem('authToken');
  if (!token) {
    throw new Error('No authentication token found');
  }

  try {
    const response = await API.get('/user_account/fitness_plans', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching fitness plans:', error);
    throw error;
  }
};
