import React, { useState, useEffect } from 'react';
import { Box, CircularProgress, Alert } from '@mui/material';
import UserTable from './UserTable';
import { fetchUsers, fetchUserAccounts, fetchFitnessPlans } from '../../api/adminDashboard';

export default function AdminDashboard() {
  const [combinedData, setCombinedData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [users, accounts, plans] = await Promise.all([
          fetchUsers(),
          fetchUserAccounts(),
          fetchFitnessPlans()
        ]);

        // Combine the data
        const combined = users.map(user => {
          const account = accounts.find(acc => acc.user_id === user.id);
          const planCount = plans.filter(plan => plan.user_account_id === account?.id).length;

          return {
            id: user.id,
            email: user.email,
            first_name: user.first_name,
            last_name: user.last_name,
            admin: account?.admin || false,
            terms_accepted: account?.terms_accepted || false,
            preferences: {
              personal: account?.personal_preferences,
              fitness: account?.fitness_preferences
            },
            created_at: account?.created_at,
            fitness_plan_count: planCount
          };
        });

        setCombinedData(combined);
        setLoading(false);
      } catch (err) {
        setError('Error fetching data');
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) return <Box display="flex" justifyContent="center"><CircularProgress /></Box>;
  if (error) return <Alert severity="error">{error}</Alert>;

  return (
    <Box sx={{ p: 4 }}>
      <UserTable users={combinedData} />
    </Box>
  );
}
