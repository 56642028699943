import React from 'react';

// MUI
import { 
  List, 
  ListItem, 
  ListItemText, 
  Paper, 
  Chip,
  Stack 
} from '@mui/material';

const FitnessPreferencesDisplay = ({ preferences }) => {
  if (!preferences) return null;

  return (
    <Paper elevation={0} sx={{ p: 1, maxWidth: 300 }}>
      <List dense disablePadding>
        <ListItem>
          <ListItemText 
            primary="Injuries"
            secondary={
              preferences.injuries?.length > 0 ? (
                <Stack direction="row" spacing={1} sx={{ mt: 0.5 }}>
                  {preferences.injuries.map((injury, index) => (
                    <Chip 
                      key={index}
                      label={injury}
                      size="small"
                      variant="outlined"
                    />
                  ))}
                </Stack>
              ) : 'None'
            }
          />
        </ListItem>

        <ListItem>
          <ListItemText 
            primary="Workout Days"
            secondary={
              preferences.workout_days?.length > 0 ? (
                <Stack direction="row" spacing={1} sx={{ mt: 0.5 }}>
                  {preferences.workout_days.map((day, index) => (
                    <Chip 
                      key={index}
                      label={day}
                      size="small"
                      color="primary"
                      variant="outlined"
                    />
                  ))}
                </Stack>
              ) : 'None specified'
            }
          />
        </ListItem>

        <ListItem>
          <ListItemText 
            primary="Access to Gym"
            secondary={
              <Chip 
                label={preferences.access_to_gym ? 'Yes' : 'No'}
                color={preferences.access_to_gym ? 'success' : 'default'}
                size="small"
                sx={{ mt: 0.5 }}
              />
            }
          />
        </ListItem>

        <ListItem>
          <ListItemText 
            primary="Fitness Level"
            secondary={preferences.fitness_level || 'Not specified'}
          />
        </ListItem>

        <ListItem>
          <ListItemText 
            primary="Workout Duration"
            secondary={preferences.amount_of_time 
              ? `${preferences.amount_of_time} minutes` 
              : 'Not specified'}
          />
        </ListItem>

        <ListItem>
          <ListItemText 
            primary="Available Equipment"
            secondary={
              preferences.available_equipment?.length > 0 ? (
                <Stack direction="row" spacing={1} sx={{ mt: 0.5 }} flexWrap="wrap">
                  {preferences.available_equipment.map((equipment, index) => (
                    <Chip 
                      key={index}
                      label={equipment}
                      size="small"
                      variant="outlined"
                    />
                  ))}
                </Stack>
              ) : 'None'
            }
          />
        </ListItem>

        <ListItem>
          <ListItemText 
            primary="Workout Preferences"
            secondary={
              preferences.workout_preferences?.length > 0 ? (
                <Stack direction="row" spacing={1} sx={{ mt: 0.5 }} flexWrap="wrap">
                  {preferences.workout_preferences.map((pref, index) => (
                    <Chip 
                      key={index}
                      label={pref}
                      size="small"
                      variant="outlined"
                    />
                  ))}
                </Stack>
              ) : 'None'
            }
          />
        </ListItem>
      </List>
    </Paper>
  );
};

export default FitnessPreferencesDisplay; 