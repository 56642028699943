import API from '../api';

// Fetch the user's account
export const fetchUserAccount = async () => {
  const token = localStorage.getItem('authToken');
  if (!token) {
    throw new Error('No authentication token found');
  }

  try {
    const response = await API.get('/user_account', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching user account:', error);
    throw error;
  }
};

export const updateUserAccount = async (userAccountData) => {
  const token = localStorage.getItem('authToken');
  if (!token) {
    throw new Error('No authentication token found');
  }

  try {
    const response = await API.put(
      '/user_account',
      { user_account: userAccountData },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error updating user account:', error);
    throw error;
  }
};
