import API from '../api';

export const fetchUsers = async () => {
  const response = await API.get('/admin/users');
  return response.data;
};

export const fetchUserAccounts = async () => {
  const response = await API.get('/admin/user-accounts');
  return response.data;
};

export const fetchFitnessPlans = async () => {
  const response = await API.get('/admin/fitness-plans');
  return response.data;
};

export const fetchUserDetails = async (userId) => {
  const response = await API.get(`/admin/users/${userId}`);
  return response.data;
};
