import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const injuryOptions = [
  { value: 'Left Arm', label: 'Left Arm' },
  { value: 'Right Arm', label: 'Right Arm' },
  { value: 'Left Leg', label: 'Left Leg' },
  { value: 'Right Leg', label: 'Right Leg' },
  { value: 'Back', label: 'Back' },
  { value: 'Chest', label: 'Chest' },
  { value: 'Neck', label: 'Neck' }
];

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const Injuries = ({ userPreferences, handleChange }) => {
  if (!userPreferences) {
    return null;
  }

  const handleSelectChange = (event, value) => {
    handleChange({
      target: {
        name: 'personal_info.injuries',
        value: value.map(injury => injury.value)
      }
    });
  };

  return (
    <div className="max-w-md">
      <Autocomplete
        multiple
        id="injuries"
        options={injuryOptions}
        disableCloseOnSelect
        getOptionLabel={(option) => option.label}
        renderOption={(props, option, { selected }) => {
          const { key, ...optionProps } = props;
          return (
            <li key={option.value} {...optionProps}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.label}
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField 
            {...params} 
            label="Injuries" 
            placeholder="Select Injuries" 
            onFocus={() => window.scrollTo(0, 0)}
          />
        )}
        onChange={handleSelectChange}
        value={injuryOptions.filter(option => 
          Array.isArray(userPreferences.injuries) && 
          userPreferences.injuries.includes(option.value)
        )}
      />
    </div>
  );
};

export default Injuries;
