import React from 'react';

// MUI Imports
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

// Field Imports
import UserSex from './formFields/PersonalInfo/UserSex';
import UserAge from './formFields/PersonalInfo/UserAge';
import UserHeight from './formFields/PersonalInfo/UserHeight';
import UserWeight from './formFields/PersonalInfo/UserWeight';
import Disabilities from './formFields/PersonalInfo/Disabilities';
import PregnancyStatus from './formFields/PersonalInfo/PregnancyStatus';
import Injuries from './formFields/PersonalInfo/Injuries';

const Item = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
}));

const FitnessPlanFormHeader = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.primary.main,
  textAlign: 'center',
}));

const PersonalInfoForm = ({ userPreferences, handleChange }) => (
  <Box sx={{ flexGrow: 1 }}>
    <Grid container spacing={1} justifyContent="center" columnSpacing={{ xs: 1, sm: 2 }}>
      <Grid item="true" size={{ xs: 12, md: 6 }}>
        <FitnessPlanFormHeader>
          Basic Information
        </FitnessPlanFormHeader>
        <Item>
          <UserSex userPreferences={userPreferences.personal_info} handleChange={handleChange} />
        </Item>
        <Item>
          <UserAge userPreferences={userPreferences.personal_info} handleChange={handleChange} />
        </Item>
      </Grid>

      <Grid item="true" size={{ xs: 12, md: 6 }}>
        <FitnessPlanFormHeader>
          Body Measurements
        </FitnessPlanFormHeader>
        <Item>
          <UserHeight userPreferences={userPreferences.personal_info} handleChange={handleChange} />
        </Item>
        <Item>
          <UserWeight userPreferences={userPreferences.personal_info} handleChange={handleChange} />
        </Item>
      </Grid>
    </Grid>

    <Grid container spacing={1} justifyContent="center" columnSpacing={{ xs: 1, sm: 2 }}>
      <Grid item="true" size={{ xs: 12, md: 6 }}>
        <FitnessPlanFormHeader>
          Disabilities & Injuries
        </FitnessPlanFormHeader>
        <Item>
          <Disabilities userPreferences={userPreferences.personal_info} handleChange={handleChange} />
        </Item>
        <Item>
          <Injuries userPreferences={userPreferences.personal_info} handleChange={handleChange} />
        </Item>
      </Grid>

      {userPreferences.personal_info.user_sex === 'female' && (
        <Grid item="true" size={{ xs: 12, md: 6 }}>
          <FitnessPlanFormHeader>
            Pregnancy Status
          </FitnessPlanFormHeader>
          <Item>
            <PregnancyStatus userPreferences={userPreferences.personal_info} handleChange={handleChange} />
          </Item>
        </Grid>
      )}
    </Grid>
  </Box>
);

export default PersonalInfoForm;
