import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { 
  Box, 
  Paper, 
  Typography, 
  Button, 
  CircularProgress, 
  Alert,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { fetchUserDetails } from '../../api/adminDashboard';
import CombinedPreferencesDisplay from './CombinedPreferencesDisplay';
import WeekTimeline from '../FitnessDietaryPlan/WeekTimeline';
import { styled } from '@mui/material/styles';

// Styled TableCell from FitnessDietaryPlans
const StyledTableCell = styled('td')(({ theme }) => ({
  padding: theme.spacing(2),
  fontSize: '0.95rem',
  color: theme.palette.text.primary,
}));

// Styled TableRow from FitnessDietaryPlans
const StyledTableRow = styled('tr')(({ theme, index }) => ({
  backgroundColor: index % 2 === 0 
    ? theme.palette.mode === 'dark' 
      ? theme.palette.grey[800] 
      : theme.palette.grey[50] 
    : theme.palette.mode === 'dark' 
      ? theme.palette.grey[900] 
      : theme.palette.common.white,
  cursor: 'pointer',
  borderBottom: `1px solid ${theme.palette.divider}`,
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

export default function AdminUserView() {
  const { userId } = useParams();
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedPlanIndex, setSelectedPlanIndex] = useState(null);

  useEffect(() => {
    const loadUserData = async () => {
      try {
        const data = await fetchUserDetails(userId);
        setUserData(data);
        setLoading(false);
      } catch (err) {
        setError('Error loading user details');
        setLoading(false);
      }
    };

    loadUserData();
  }, [userId]);

  if (loading) return <Box display="flex" justifyContent="center"><CircularProgress /></Box>;
  if (error) return <Alert severity="error">{error}</Alert>;
  if (!userData) return <Alert severity="error">User not found</Alert>;

  const { user, user_account, fitness_plans } = userData;

  const formattedDate = (date) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(date).toLocaleDateString('en-US', options);
  };

  return (
    <Box sx={{ p: 4 }}>
      <Button
        startIcon={<ArrowBackIcon />}
        onClick={() => navigate('/admin')}
        sx={{ mb: 3 }}
      >
        Back to Users
      </Button>

      <Paper sx={{ p: 3, mb: 3 }}>
        <Grid container spacing={3}>
          <Grid xs={12} md={6} component="div">
            <Typography variant="h6" gutterBottom>User Details</Typography>
            <Typography><strong>ID:</strong> {user.id}</Typography>
            <Typography><strong>Email:</strong> {user.email}</Typography>
            <Typography><strong>Name:</strong> {user.first_name} {user.last_name}</Typography>
            <Typography><strong>Created:</strong> {new Date(user_account.created_at).toLocaleDateString()}</Typography>
          </Grid>
          <Grid xs={12} md={6} component="div">
            <Typography variant="h6" gutterBottom>Account Status</Typography>
            <Typography><strong>Admin:</strong> {user_account.admin ? 'Yes' : 'No'}</Typography>
            <Typography><strong>Terms Accepted:</strong> {user_account.terms_accepted ? 'Yes' : 'No'}</Typography>
            <Typography><strong>Fitness Plans:</strong> {fitness_plans.length}</Typography>
          </Grid>
        </Grid>
      </Paper>

      <CombinedPreferencesDisplay 
        personalPreferences={user_account.personal_preferences}
        fitnessPreferences={user_account.fitness_preferences}
      />

      {fitness_plans.length > 0 && (
        <Paper sx={{ p: 3, mt: 3 }}>
          <Typography variant="h6" gutterBottom>Fitness Plans</Typography>
          {selectedPlanIndex !== null ? (
            <>
              <Button 
                variant="outlined" 
                onClick={() => setSelectedPlanIndex(null)}
                sx={{ mb: 2 }}
              >
                Back to Plans List
              </Button>
              <WeekTimeline
                fitnessPlan={fitness_plans[selectedPlanIndex]?.fitness_plan}
                dietaryPlan={fitness_plans[selectedPlanIndex]?.dietary_plan}
                planId={fitness_plans[selectedPlanIndex]?.id}
              />
            </>
          ) : (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>ID</StyledTableCell>
                    <StyledTableCell>Plan Name</StyledTableCell>
                    <StyledTableCell>Created</StyledTableCell>
                    <StyledTableCell>Actions</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {fitness_plans.map((plan, index) => (
                    <StyledTableRow key={plan.id} index={index}>
                      <StyledTableCell>{plan.id}</StyledTableCell>
                      <StyledTableCell>{plan.fitness_plan.name}</StyledTableCell>
                      <StyledTableCell>{formattedDate(plan.created_at)}</StyledTableCell>
                      <StyledTableCell>
                        <Button 
                          variant="outlined" 
                          onClick={() => setSelectedPlanIndex(index)}
                        >
                          View
                        </Button>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Paper>
      )}
    </Box>
  );
} 