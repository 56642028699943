import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const API = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL || 'https://pauls-fitness-app-29874b7e6de3.herokuapp.com',
  withCredentials: true,
  headers: {
    'Accept': 'application/json',
    'Access-Control-Allow-Origin': 'https://trythryv.com'
  }
});

// Remove the default Content-Type header as it will be set automatically for multipart/form-data
delete API.defaults.headers['Content-Type'];

export const useAxiosInterceptors = () => {
  const navigate = useNavigate();

  API.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('authToken');
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  API.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response?.status === 401 || 
          error.response?.data?.error === 'Signature has expired') {
        localStorage.removeItem('authToken');
        navigate('/signin');
      }
      return Promise.reject(error);
    }
  );
};

export default API;
