import React, { createContext, useState, useCallback, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

const FlashMessageContext = createContext();

export const FlashMessageProvider = ({ children }) => {
  const [flashMessages, setFlashMessages] = useState([]);
  const timeouts = useRef(new Map());
  const navigate = useNavigate();

  const addFlashMessage = useCallback((text, error = false, linkPath = '', linkText = '') => {
    const id = Date.now();
    const newMessage = { 
      id, 
      text, 
      error,
      linkPath,
      linkText
    };

    setFlashMessages(prevMessages => [...prevMessages, newMessage]);

    const timeout = setTimeout(() => {
      setFlashMessages(messages => messages.filter(message => message.id !== id));
      timeouts.current.delete(id);
    }, 5000);

    timeouts.current.set(id, timeout);
  }, []);

  const removeFlashMessage = useCallback((id) => {
    if (timeouts.current.has(id)) {
      clearTimeout(timeouts.current.get(id));
      timeouts.current.delete(id);
    }
    setFlashMessages(messages => messages.filter(message => message.id !== id));
  }, []);

  const handleLinkClick = useCallback((id, linkPath) => {
    removeFlashMessage(id);
    navigate(linkPath);
  }, [navigate, removeFlashMessage]);

  React.useEffect(() => {
    const currentTimeouts = timeouts.current;
    
    return () => {
      currentTimeouts.forEach(timeout => clearTimeout(timeout));
      currentTimeouts.clear();
    };
  }, []);

  return (
    <FlashMessageContext.Provider value={{
      flashMessages,
      addFlashMessage,
      removeFlashMessage,
      handleLinkClick
    }}>
      {children}
    </FlashMessageContext.Provider>
  );
};

export const useFlashMessage = () => {
  const context = useContext(FlashMessageContext);
  if (!context) {
    throw new Error('useFlashMessage must be used within a FlashMessageProvider');
  }
  return context;
};
